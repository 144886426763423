import { useEffect, useState } from "react";
import { Badge, Card, Row, Col } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";
import { collection, onSnapshot, query } from "firebase/firestore";
import { firestore } from "../../firebase/Firebase";
import { CheckSquare, MinusSquare, Square } from "react-feather";
import CountUp from "react-countup";
import moment from "moment/moment"; 
import { Calendar } from "react-feather";
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space, Tooltip, message } from 'antd';

const countUpFormatter = (value) => <CountUp end={value} separator="," duration="2"  />;

const WorkorderStatistcs = () => {
  const [workorders, setWorkorders] = useState([]);
  const { user } = useAuth();
  const [msg, msgContextHolder] = message.useMessage();
  const clientId = user.client;
  const dateFormat = 'DD-MMM-YYYY';  
  const [completed, setCompleted] = useState(null);
  const [assigned, setAssigned] = useState(null);
  const [unassigned, setUnassigned] = useState(null);
  const [menuProps, setMenuProps] = useState([]);
  
  useEffect(() => {
    ListWorkOrders("");   
  } , [clientId, msg]);

  //Days dropdown
  const getLast10Days = () => {    
    const items = [];
    const current = moment();
    let n=10
    let x= 0;
    while(n>0){       
      let resDate = {};                 
      items.push(resDate);
      current.subtract(1,"day")
      resDate['label'] = current.format(dateFormat);
      resDate['key'] = current.format(dateFormat);  
      n--;      x++;     
    }      
    const menus = {
      items,
      selectable: true,
      onClick: handleMenuClick,
    };    
    setMenuProps(menus);  
  };

  
  const ListWorkOrders = (sInput) => {
    try {    
      let srhDate = sInput;     

      let cntCompleted =0;
      let cntAssigned =0;
      let cntUnassigned =0;
 
      //Days dropdown
      /*const items = [];
      const current = moment();
      let n=10
      let x= 0;
      while(n>0){       
        let resDate = {};                 
        items.push(resDate);
        current.subtract(1,"day")
        resDate['label'] = current.format(dateFormat);
        resDate['key'] = x;  
        n--;      x++;     
      }      
      const menus = {
        items,
        selectable: true,
        onClick: handleMenuClick,
      };    
      setMenuProps(menus);        
      setLastTenDates(items); 
      console.log('LastTenDates>',lastTenDates);  */  
      //

      const qry = query(collection(firestore, `/${clientId}/details/workorders`));  
      const unsubscribe = onSnapshot(qry, (snapshot) => {
        if(snapshot.docs)
        {
          const workorders = [];            
          snapshot.forEach((doc) => {     
            const workorder = {};
            workorder['key'] = doc.id;              
            const filWo = doc.data(); 
            workorder['DeliveryDate'] =  filWo.delDate[0];   
            workorder['Status'] =  filWo.woStatus;          
            workorders.push(workorder);     
            //console.log("workorders=>",workorders);   
          });
                 
          let woFilterData=workorders;
          if(srhDate === undefined || srhDate === ""){
            srhDate =  moment().format(dateFormat);
          }

          woFilterData = woFilterData.filter(
            customer => {
              return (
                moment(customer.DeliveryDate, dateFormat).isSame(moment(srhDate, dateFormat)) 
              );
            }
          );    
          
          let woFilterCData = woFilterData.filter(
            customer => {
              return (
                typeof customer.Status === "string" &&  customer.Status.toLowerCase().includes('completed') 
              );
            }
          ); 
          cntCompleted = woFilterCData.length;

          let woFilterAData = woFilterData.filter(
            customer => {
              return (
                typeof customer.Status === "string" &&  customer.Status.toLowerCase() === 'assigned' 
              );
            }
          ); 
          cntAssigned = woFilterAData.length;

          let woFilterUData = woFilterData.filter(
            customer => {
              return (
                typeof customer.Status === "string" &&  customer.Status.toLowerCase() === 'unassigned'
              );
            }
          );                 
          cntUnassigned = woFilterUData.length;  
         
          setCompleted(cntCompleted);
          setAssigned(cntAssigned);
          setUnassigned(cntUnassigned);

          setWorkorders(woFilterData);         
          getLast10Days(); 
        }
        else
        {
          msg.open({type: 'warning', content: 'No Work Order found.'});
        }
      },(error) => {         
        msg.open({type: 'error', content: error.message});        
      },
      );
      return unsubscribe;
    } catch(error) {
      msg.open({
        type: 'error',
        content: error.message,
        duration: 10
      });
    }      
  }
   
  const divBorder = {
    border: '0px solid #bababa61',
    height: '35px',
    paddingLeft: '2px'
  }
  const divLeft = {
    float: 'left',
    paddingTop: '5px'
  }
  
  const divRight = {
    float: 'right',
  }

  //    
  const handleMenuClick = (e) => {
    //message.info('Click on menu item.');   
    //console.log(e['key']);
    ListWorkOrders(e['key']);
  };

  
  return (
    <div>
      <Row className="mb-2 mb-xl-3">
            <Col xs="auto" className="d-none d-sm-block">
                <h3>Dashboard</h3>
            </Col>
            <Col xs="auto" className="ms-auto text-end mt-n1">           
               <div >
                    <div style={divLeft} className="divLeft"></div>
                    <div style={divRight} className="divRight">                 
                    <Dropdown menu={menuProps} onClick={handleMenuClick}>
                      <Button>
                        <Space>
                          Today
                          <Calendar className="feather align-middle mt-n1" />
                        </Space>
                      </Button>
                    </Dropdown>
                    </div>
                </div>
            </Col>            
        </Row>
        <Row>           
           <Col md="6" xl className="d-flex">
               <Card className="flex-fill">
                   <Card.Body className="py-4">
                       <div className="d-flex align-items-start">
                           <div className="flex-grow-1">
                               <h3 className="mb-2 text-success">{countUpFormatter(completed)}</h3>
                               <p className="mb-2 text-success">Completed</p>                               
                           </div>
                           <div className="d-inline-block ms-3">
                               <div className="stat">
                               <CheckSquare className="align-middle text-success" stroke="green" />
                               </div>
                           </div>
                       </div>
                   </Card.Body>
               </Card>
           </Col>
           <Col md="6" xl className="d-flex">
               <Card className="flex-fill">
                   <Card.Body className=" py-4">
                       <div className="d-flex align-items-start">
                           <div className="flex-grow-1">
                               <h3 className="mb-2 text-primary">{countUpFormatter(assigned)} </h3>
                               <p className="mb-2 text-primary">Assigned</p>                            
                           </div>
                           <div className="d-inline-block ms-3">
                               <div className="stat">
                                   <MinusSquare className="align-middle text-primary" stroke="blue" />
                               </div>
                           </div>
                       </div>
                   </Card.Body>
               </Card>
           </Col>
           <Col md="6" xl className="d-flex">
               <Card className="flex-fill">
                   <Card.Body className=" py-4">
                       <div className="d-flex align-items-start">
                           <div className="flex-grow-1">
                               <h3 className="mb-2 text-danger">{countUpFormatter(unassigned)}</h3>
                               <p className="mb-2 text-danger">Unassigned</p>                            
                           </div>
                           <div className="d-inline-block ms-3">
                               <div className="stat">
                                   <Square className="align-middle text-danger" stroke="red" />
                               </div>
                           </div>
                       </div>
                   </Card.Body>
               </Card>
           </Col>
       </Row>
    </div>
   
    );
};

export default WorkorderStatistcs;