// Layouts
import AuthGuard from "./components/guards/AuthGuard";
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import PublicLayout from "./layouts/Public";
import SignInPage from "./pages/auth/signIn";
import CustomersPage from "./pages/customers/CustomersPage";
import DashboardPage from "./pages/dashboard/DashboardPage";
import DriversPage from "./pages/drivers/DriversPage";
import ReceiptsPage from "./pages/receipts/ReceiptsPage";
import TerminalsPage from "./pages/terminals/TerminalsPage";
import WorkordersPage from "./pages/workorders/WorkordersPage";
import WorkorderViewPage from "./pages/workorders/WorkorderViewPage";
import Support from "./pages/support/SupportViewPage";
import Privacy from "./components/public/privacy";
import HelpCenter from "./components/public/helpcenter";
import Terms from "./components/public/terms";
import ResetPassword from "./pages/auth/reset-password";

const routes = [
    {
        path: "/",
        element: <AuthLayout />,
        children: [
            {
                path: "",
                element: <SignInPage />
            }
        ]
    },
    {
        path: "auth",
        element: <AuthLayout />,
        children: [
            {
                path: "sign-in",
                element: <SignInPage />
            },{
                path: "reset-password",
                element: <ResetPassword />
            }
        ]
    },   
    {
        path: "public",
        element : (           
            <PublicLayout/>
        ),
        children: [
            {
                path: "support",
                element: <Support />
            },
            {
                path: "privacy",
                element: <Privacy />
            },
            {
                path: "helpcenter",
                element:  <HelpCenter/>
            },
            {
                path: "terms",
                element: <Terms/>
            }
        ]
    },    
    {
        path: "manage",
        element : (
          
                <DashboardLayout/>
           
        ),
        children: [
            {
                path: "",
                element: <DashboardPage />
            },
            {
                path: "workorders",
                element: <WorkordersPage />
            },
            {
                path: "receipts",
                element: <ReceiptsPage />
            },
            {
                path: "drivers",
                element: <DriversPage />
            },
            {
                path: "customers",
                element: <CustomersPage />
            },
            {
                path: "terminals",
                element: <TerminalsPage />
            },
            {
                path:'workorderview',
                element: <WorkorderViewPage/>
            },
            {
                path:'support',
                element: <Support/>
            }
        ]
    },
];

export default routes;