import { Space } from "antd";
import { Helmet } from "react-helmet-async";
const Terms = () => {
    return (  
<Space>
    <Helmet title="Terms of Service" />
    <h1 className="h3 mb-3">Terms of Service</h1> 
    <br></br> 
    Terms Text coming soon!
</Space>
    )
};

export default Terms; 