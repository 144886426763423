import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import DriversList from "../../components/drivers/DriversList";

const DriversPage = () => (
    <React.Fragment>
        <Helmet title="Drivers" />
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Drivers</h1>
            <DriversList />
        </Container>       
    </React.Fragment>
);

export default DriversPage;