import {   
    Sliders,
    FileText,
    Truck,    
    User,    
    MapPin,
    Database,   
  } from "react-feather";

const pagesSection = [
    {
        href: "/manage",
        icon: Sliders,
        title: "Dashboard"
    },
    {
        href: "/manage/workorders",
        icon: Truck,
        title: "Workorders",
    },
    {
        href: "/manage/receipts",
        icon: FileText,
        title: "Receipts",
    },
    {
        href: "/manage/drivers",
        icon: User,
        title: "Drivers",
    },
    {
        href: "/manage/customers",
        icon: MapPin,
        title: "Customers",
    },
    {
        href: "/manage/terminals",
        icon: Database,
        title: "Terminals",
    },
];

const navItems = [
    {
      title: "Pages",
      pages: pagesSection,
    },    
  ];
  

export default navItems;