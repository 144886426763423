import { Space } from "antd";
import { Helmet } from "react-helmet-async";
const HelpCenter = () => {
    
    return (  
        <Space>  <Helmet title="Help Center" /> <h1 className="h3 mb-3">Help Center</h1>
        <br></br>HelpCenter Text coming soon!</Space>
    )
};

export default HelpCenter; 