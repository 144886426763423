import React from "react";
import Content from "../components/Content";
import { Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import Main from "../components/Main";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Wrapper from "../components/Wrapper";

const backImg = { 
    backgroundImage: "url('/img/gas-truck.jpg')",
    
  }

  const contHeight={
    height: '100%'
  }
const Auth = ({children}) => (
    <React.Fragment>
         <Wrapper>
        <Main >
            <Header />
            <div style={backImg} className="h-100">
            <Content >
                <Container className="d-flex flex-column">               
                    <Col sm="10" md="8" lg="6" className="mx-auto d-table h-100">
                        <div className="d-table-cell align-middle">
                            {children}
                            <Outlet />
                        </div>
                    </Col>                
                </Container>
            </Content>
            </div>
            <Footer />
        </Main>
        </Wrapper>
    </React.Fragment>
);

export default Auth;