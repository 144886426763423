import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const header = { 
  innerHeight:'162px',
  backgroundColor:'#293042',
}
const leadTitle = { 
  color: '#ffffff',
}
const Header = () => (
  <header style={header} className="header">
    <Container fluid>
      <Row className="text-muted">
        <Col xs='auto' className="logo-style" >   
              <span className="text-muted" href="/">
                <img src='/img/talentmindsllc-white.png' width={90}></img>
              </span>
        </Col>
        <Col xs='auto' className="logo-title">
        <div>
            <span className="text-muted " href="/">
              <h1 style={leadTitle}>Fleet Suite Management System</h1>
              </span>
            </div>
        </Col>
      </Row>
    </Container>
  </header>
);

export default Header;
