import * as React from "react";
import { FileExcelOutlined,FilePdfOutlined, ExclamationCircleOutlined,DeleteOutlined,UserOutlined } from '@ant-design/icons';
import { Button, Card, message, Modal, Row, Space,  Table, DatePicker, Avatar,Tag, Input,Form,Radio,Select   } from "antd";
import { collection, onSnapshot, query,doc, setDoc,updateDoc,deleteDoc  } from "firebase/firestore";
import { useEffect, useState,useRef } from "react";
import { firestore } from "../../firebase/Firebase";
import  useAuth from '../../hooks/useAuth';
import jsPDF from 'jspdf';  
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const ReceiptsList = () => {
  const [receipts, setReceipts] = useState([]);
  const [editingReceipts, seteditingReceipts] = useState('');  
  const [isEditing, setIsEditing] = useState(false);
  const [msg, msgContextHolder] = message.useMessage(); 
  const [chekedRows, setchekedRows] = useState(false);
  const [chekedRowsData, setchekedRowsData] = useState([]); 
  const { user } = useAuth();
  const clientId = user.client;
  const [status] = useState('1');
  const [searchStatus] = useState('1');
 
  useEffect(() => {
    ListReceipts("");      
  }, []);
  
  const columns = [ 
    {
      title: 'Date',
      dataIndex: 'receiptDate',     
      key: 'receiptDate',
    },       
    {
      title: 'Station Name',
      dataIndex: 'stationName',     
      key: 'stationName',   
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.stationName.localeCompare(b.stationName)
    },
    {
      title: 'Payment Type',
      dataIndex: 'paymentType',     
      key: 'paymentType',   
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.paymentType.localeCompare(b.paymentType)
    },
    {
      title: 'Amount',
      dataIndex: 'amount',     
      key: 'amount',   
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.amount.localeCompare(b.amount)
    },
    {
      title: 'Driver Name',
      dataIndex: 'reciptUser',     
      width: '10%',        
      key: 'reciptUser',  
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.reciptUser.localeCompare(b.reciptUser)
    },  
    {
        title: 'Receipt',
        dataIndex: 'receiptUrl',
        render: (_, record) => (
            <Space size="middle">          
             <a href={ record.receiptUrl } target='_new'><Avatar shape="square" size="large" src={ record.receiptUrl } icon={<UserOutlined />} /></a>
            </Space>
        ),
        width: '10%',
    },
    {
      title: 'Note',
      dataIndex: 'note',   
      key: 'note',   
      width: '20%',
    },        
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (record) => (  
        <span>         
        <Space size="middle">    
          <Tag color={record === "Approved" ? "success" : "processing"}  key={record}>
            {record}
          </Tag>
        </Space>
        </span>
      ),     
    },
  ];
 
const exportExcel= (e) => {
  try {    
    //console.log("exportExcel=>",chekedRows);
    let totalRows = chekedRowsData.length;
   
    if(totalRows ===0){
      msg.open({type: 'warning', content: 'Please select the receipt(s) to export excel!'});   
    }
    else
    {   
      exportExcelFile(); 
    }
  } catch(error) {
    msg.open({ type: 'error',  content: error.message,  duration: 10 });
  } 
}


const exportExcelFile= (e) => { 
  //console.log("chekedRowsData=>",chekedRowsData)
  let totalRows = chekedRowsData.length;
 
  if(totalRows ===0){
    msg.open({type: 'warning', content: 'Please select the receipt(s) to download PDF!'});    
  }
  else
  {     
    let receiptData =[];
    console.log('receipts=>',receipts);
    receipts.forEach(rec => {
      chekedRows.split(',').map((receiptId) => {        
        if(rec.key===receiptId){                  
          const receipt = {};
          receipt['Receipt Date'] = rec.receiptDate;
          receipt['Station Name'] = rec.stationName;
          receipt['Payment Type'] = rec.paymentType;
          receipt['Amount'] = rec.amount;
          receipt['Driver Name'] = rec.reciptUser;
          receipt['Note'] = rec.note;
          //receipt['receiptUrl'] = rec.receiptUrl;
          receipt['Status'] = rec.status; 
          receiptData.push(receipt);       
        }
      });
    })  
   
    var date=new Date(); 
    let exlFileName = "Receipts_"+ (date.getMonth()+1) +"_"+date.getDate()+"_"+date.getFullYear()+"_"+date.getHours()+"_"+date.getMinutes() +"_"+date.getSeconds() +".xlsx";
    
    console.log('receiptData->',receiptData);
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const ws = XLSX.utils.json_to_sheet(receiptData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data1 = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data1, exlFileName);    
  }
}
 
const exportPDF= (e) => { 
  let totalRows = chekedRowsData.length;
  let cnt = 0;
  if(totalRows ===0){
    msg.open({type: 'warning', content: 'Please select the receipt(s) to download PDF!'});    
  }
  else
  {  
    var doc = new jsPDF();
    doc.setFontSize(40);
    doc.text("Receipts", 35, 25);
    
    chekedRowsData.forEach((rec) => { 
      doc.addImage(encodeURIComponent(rec.receiptUrl), "JPEG", 15, 40, 180, 180);
      cnt++;
      if(cnt < totalRows){
        doc.addPage("a4", "p");
      }    
    });
    var date=new Date(); 
    let fileName = "Receipt_"+ (date.getMonth()+1) +"_"+date.getDate()+"_"+date.getFullYear()+"_"+date.getHours()+"_"+date.getMinutes() +"_"+date.getSeconds() +".pdf";
    doc.save(fileName); 
  }
}

const { confirm } = Modal;
const showDeleteConfirm = () => {
  if(chekedRows === false){
    msg.open({type: 'warning', content: 'Please select the receipt(s) to delete!'});    
  }
  else
  { 
    confirm({
      title: 'Delete Confirmation',
      icon: <ExclamationCircleOutlined  />,
      content: 'Are you sure want to delete the selected receipt(s)?',
      okText: 'Yes',    
      cancelText: 'No',   
      onOk() {
        console.log("deleteWOs=>",chekedRows);   
        DeleteWorkOrders(chekedRows); 
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
};


const DeleteWorkOrders = (sInput) => {
  try {      
    if(sInput === false){
      msg.open({type: 'warning', content: 'Please select the work order(s) to delete!'});    
    }
    else
    { 
      sInput.split(',').map((woId) => {
        deleteDoc(doc(firestore, `/${clientId}/details/receipt`, woId));
      });
      msg.open({ type: 'success',  content:'Work Order(s) has beed deleted successfully!',  duration: 10 });
    }
  } catch(error) {
    msg.open({ type: 'error',  content: error.message,  duration: 10 });
  }      
}


const handleOk = (e) => {
  //console.log(e);
  setIsEditing(false);
};

const handleCancel = (e) => {
  //console.log(e);
  setIsEditing(false);
};  

const onFormLayoutChange = ( ) => {
  //setFormLayout(layout);
};

const [form] = Form.useForm();  
 
  //Popup
  const [isModalOpen, setIsModalOpen] = useState(false);   

  
  const handleCancelNew = () => {
    setIsModalOpen(false);
  };

  const [messageApi, contextHolder] = message.useMessage();

  //Edit Popup - Save button onclick - Cusotmer Details to Firebase
  const onSaveReceipt = (values) => {   
    try
    {
      updateDoc(doc(firestore, `/${clientId}/details/receipt`,values.CustomerKey ), {      
        companyId: values.CustomerKey,
        companyName: values.CompanyName,
        contacts: values.Contact,
        //address: values.Address,
        emailId: values.EmailId,
        //phPrefix: values.PrePhone,
        phNo: values.Phone,
        status: values.Status,
      });
      msg.open({type: 'success', content: 'Receipt details has been added successfully.'});      
      handleCancel();
    } catch (error) {
      msg.open({type: 'error', content: error.message});           
    }
  };
 
 
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    /*number: {
      range: '${label} must be between ${min} and ${max}',
    },*/
  };

  const ListReceipts = (sInput) => {
  try 
  {  
    let srhText =sInput.srchText;
    let srhStatus = sInput.srchStatus;
    //console.log(srhText , srhStatus);
    const qry = query(collection(firestore, `/${clientId}/details/receipt`));    
    const unsubscribe = onSnapshot(qry, (snapshot) => {
    
      if(snapshot.docs) {       
          const receiptData = [];   
          snapshot.forEach((doc) => {        
            const receipt = {};
            receipt['key'] = doc.id;              
            const filReceipt = doc.data();
            receipt['receiptDate'] =   filReceipt.receiptDate;
            receipt['stationName']=   filReceipt.stationName;
            receipt['paymentType']=   filReceipt.paymentType;
            receipt['amount'] =   filReceipt.amount;
            receipt['reciptUser'] =   filReceipt.reciptUser;
            receipt['note'] = filReceipt.note;           
            receipt['receiptUrl'] =   filReceipt.receiptUrl;
            receipt['status'] =  filReceipt.status; 
            receiptData.push(receipt);           
          }); 
        
          let receiptFilterData=receiptData;

          if(srhText !== undefined) {
            receiptFilterData = receiptData.filter(
              receipt => {
                return (
                  (typeof receipt.key === "string" && receipt.key.toLowerCase().includes(srhText.toLowerCase())) ||
                  (typeof receipt.createdBy === "string" && receipt.createdBy.toLowerCase().includes(srhText.toLowerCase())) ||
                  (typeof receipt.receiptDate === "string" && receipt.receiptDate.toLowerCase().includes(srhText.toLowerCase())) ||
                  (typeof receipt.note === "string" && receipt.note.toLowerCase().includes(srhText.toLowerCase()))
                );
              }
            );
          }    

          if(srhStatus === 'All'){
            receiptFilterData = receiptFilterData.filter(
                receipt => {
                return (
                  (typeof receipt.status === "string" && receipt.status === 'Approved') ||
                  (typeof receipt.status === "string" && receipt.status === 'Pending')
                );
              }
            );
          } 

          if(srhStatus === 'Approved' || srhStatus ===  'Pending'){
            receiptFilterData = receiptFilterData.filter(
                receipt => {
                return (
                  typeof receipt.status === "string" && receipt.status === srhStatus
                );
              }
            ); 
          }
          //console.log("receiptFilterData => ",  receiptFilterData);
          setReceipts(receiptFilterData);                  
        } else {
          msg.open({type: 'warning', content: 'No receipt found.'});
        }
      },(error) => {         
        msg.open({type: 'error', content: error.message});           
      },
    ); 
    return unsubscribe;
  } catch(error) {
      msg.open({ type: 'error', content: error.message,  duration: 10 });
  }
}
  
  const SearchReceipts = (e) => { 
   
    let srchText = e[0].value;
    let srchStatus = e[1].value;
    const SearchInput = {
      srchText: srchText,
      srchStatus: srchStatus
    }
    ListReceipts(SearchInput);    
  }
  
const cardstyle = {
  width:'100%',
}

const inputStyle= {
  width:'300px',
}


const divStyle = { 
  width:'100%',
  float: 'left',
}

const divLeft = {
  float: 'left',
  width:'60%',
}

const divRight = {
  float: 'right',
}

const hideTxtBx = {
  display: 'none',
}

const { Search } = Input; 
 
const [checkStrictly, setCheckStrictly] = useState(false);
// rowSelection objects indicates the need for row selection
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    setchekedRows(`${selectedRowKeys}`); 
    setchekedRowsData(selectedRows); 
  },
  onSelect: (record, selected, selectedRows) => {
    //console.log("onSelect",record, selected, selectedRows);
    setchekedRowsData(selectedRows); 
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    //console.log(selected, selectedRows, changeRows);
    setchekedRowsData(selectedRows); 
  },
};

return (
      <Row gutter={{
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
      }}>        
       {msgContextHolder}       
       <Card title="" style={cardstyle}>   
          <div style={cardstyle}>
            <div style={divStyle}>
                <div style={divLeft}>
                <Space>
                 <Form form={form} name="frmSearch"  layout="inline" initialValues={{ SearchStatus: 'All'}} 
                    onFieldsChange={(_, allFields) => {  SearchReceipts(allFields); }}>
                    <Form.Item label="" name="SearchWord" style={inputStyle} >
                    <Search placeholder="Search by ID, Name, Date & Note" allowClear maxLength={20} title="Search by ID, Name, Date & Note"/>
                    </Form.Item>
                    <Form.Item label="" name="SearchStatus" >
                      <Radio.Group  value={searchStatus} optionType="button" buttonStyle="solid">
                      <Radio value='Approved'>Approved</Radio>
                        <Radio value='Pending'>Pending</Radio>
                        <Radio value='All'>All</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Form>
                  </Space>
                </div>
                <div style={divRight}>  
                  <Space>
                  <Button type="primary" icon={<FileExcelOutlined/>} onClick={exportExcel} title="Export Excel">Export Excel</Button>
                  <Button type="primary" icon={<FilePdfOutlined/>} onClick={exportPDF}>Download PDF</Button>
                  <Button type="primary" icon={ <DeleteOutlined/>}  title="Delete" onClick={showDeleteConfirm} danger>Delete</Button>
                  </Space>
                </div>
            </div>
          </div>
          <div>&nbsp;</div>

          <div style={divStyle}>      
            <Table rowKey={receipts.key} dataSource={receipts} columns={columns} size="small" pagination={{ pageSizeOptions: ["10", "20","30", "40","50"], pageSize:10, showSizeChanger:false, defaultPageSize: 10 }}  rowSelection={{
            ...rowSelection, }}></Table>
          </div>
        </Card>
     
        <ModalForm open={isModalOpen} onCancel={handleCancelNew} />        
        <Modal title="Edit Receipt" centered  open={isEditing}  okText="Save Changes"  onOk={handleOk}   onCancel={handleCancel} 
          maskClosable={false}   footer={[
            <Button key="back" onClick={handleCancel}>Cancel</Button>,
            <Button key="submit"  type="primary" htmlType="submit">Save</Button> 
          ]} >
          <Form form={form} layout="horizontal" labelCol={{span: 8}} wrapperCol={{span: 16}} autoComplete="off" onValuesChange={onFormLayoutChange}
            initialValues={{
              ReceiptKey: editingReceipts?.key,
              DriverName: editingReceipts?.DriverName,
              DateOfReceipt: editingReceipts?.DateOfReceipt,              
              Note: editingReceipts?.Note,
              Status: editingReceipts?.Status,
            }} 
            onFinish={onSaveReceipt} validateMessages={validateMessages}>
            <Form.Item label="Key" name="ReceiptKey" style={hideTxtBx}><Input disabled={true}  /></Form.Item>
            <Form.Item label="Driver Name" name="DriverName" rules={[{ required: true, message: 'Please select the driver name!' }]} >
            <Input  />
            </Form.Item>
            <Form.Item label="Date of Receipt" name="DateOfReceipt" rules={[{ required: true, type: 'date' }]} >
            <DatePicker  />
            </Form.Item>            
            <Form.Item label="Note" name="Note" >
            <Input/>
            </Form.Item>              
            <Form.Item label="Status" name="Status"  rules={[{ required: true, message: 'Please select the receipt status!' }]}>
                <Radio.Group value={status} optionType="button" buttonStyle="solid">
                  <Radio value={1}>Active</Radio>
                  <Radio value={0}>Inactive</Radio>
                </Radio.Group>
            </Form.Item>
          </Form>          
          {contextHolder}
      </Modal>
    </Row>      
    ); 
};


//New Customer Form.
// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({ form, open }) => {
  const prevOpenRef = useRef();
  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;
  useEffect(() => {
    if (!open && prevOpen) {
      form.resetFields();
    }
  }, [form, prevOpen, open]);
};

//const [status, setStatus] = useState('1'); 
const { Option } = Select;

const prefixSelector = (
  <Form.Item name="phPrefix" noStyle>
    <Select style={{width: 70,}}>        
      <Option value="+1">+1</Option>
    </Select>
  </Form.Item>
);
const ModalForm = ({ open, onCancel }) => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  useResetFormOnCloseModal({
    form,
    open,
  });
  const onOk = () => {       
    form.submit();    
  };

  useEffect(() => {
    ListReceipts('');
  } , [messageApi]);

  const { user } = useAuth();
  const clientId = user.client;

  const onFinish = (values) => { 
    try
    {      
        let receiptId = generateId(20);
       
        setDoc(doc(firestore, `/${clientId}/details/receipt`,receiptId ), { 
          driverName: values.driverName,
          createdBy: values.createdBy,
          receiptDate: values.receiptDate,
          note: values.note,
          receiptUrl: values.receiptUrl,
          status: values.status,
        });       
 
        success();
        onCancel();
      
    } catch  (error){
      messageApi.open({type: 'error', content: error.message});       
    }
  };

  const [customerSearch, setReceipts] = useState([]);
  const ListReceipts = () => {
    try {
      const qry = query(collection(firestore, `/${clientId}/details/customers`)); 
      onSnapshot(qry, (snapshot) =>  {    
        if(snapshot.docs) {       
            const customerData = [];   
            snapshot.forEach((doc) => {        
               //console.log(doc.id, " => ", doc.data());
              const customer = {};
              customer['key'] = doc.id;              
              const filCustomers = doc.data();
              customer['companyName'] =   filCustomers.companyName;          
              customerData.push(customer);           
            });
  
          setReceipts(customerData);
          //console.log(  "customerData => ", customerData);
        }       
      });
     } catch (error) {
      messageApi.open({type: 'error', content: error.message}); 
    }
  };
 

 
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Customer details has been added successfully.',
    });
  };


function generateId(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
} 



const [status] = useState('1');
const [value, setValue] = useState(1);
  
return (   
    <Modal 
      title="New Receipt" 
      open={open} 
      onOk={onOk} 
      onCancel={onCancel}
      centered  
      maskClosable={false}
      footer={[
        <Button key="back" onClick={onCancel}>Cancel</Button>,
        <Button key="submit" type="primary" htmlType="submit" onClick={onOk}>Save</Button>
      ]} 
      >
       <Form form={form} name="frmNewReceipt"  onFinish={onFinish} layout="horizontal" labelCol={{span: 8}} wrapperCol={{span: 16}}
       initialValues={{ Status: 1, }} >          
        <Form.Item label="Driver Name" name="DriverName" rules={[{ required: true, message: 'Please select the driver name!' }]} >
            <Input  />
        </Form.Item>
        <Form.Item label="Date of Receipt" name="DateOfReceipt" rules={[{ required: true, type: 'date',message: 'Please select the Date of Receipt!'  }]} >
            <DatePicker   />
        </Form.Item>            
        <Form.Item label="Note" name="Note">
            <Input/>
        </Form.Item>      
        <Form.Item label="Status" name="Status"  rules={[{ required: true, message: 'Please select the receipt status!' }]}>
            <Radio.Group  value={value} optionType="button" buttonStyle="solid">
                <Radio value={1}>Active</Radio>
                <Radio value={0}>Inactive</Radio>
            </Radio.Group>
        </Form.Item>
        </Form>
        {contextHolder}
    </Modal>
  );
};

export default ReceiptsList; 