import * as React from "react";
import { EditOutlined, UserAddOutlined,PlusSquareOutlined,DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Col,  Divider,  message, Modal, Row, Space,  Table,InputNumber,  Input,Form,Radio,Select,Switch,Popconfirm,Typography   } from "antd";
import { collection, onSnapshot, query,Timestamp,doc, setDoc,updateDoc,addDoc,getDoc,getDocs,deleteField,deleteDoc  } from "firebase/firestore";
import { useEffect, useState,useRef } from "react";
import { firestore } from "../../firebase/Firebase";
import useAuth from '../../hooks/useAuth';
import { DatePickerProps } from "antd";

const CustomersList = () => {
  const [customers, setCustomers] = useState([]);
  const [addresses, setAddresses]= useState([]);
  const [editingCustomer, setEditingCustomer] = useState('');  
  const [isEditing, setIsEditing] = useState(false);
  const [msg, msgContextHolder] = message.useMessage(); 

  const { user } = useAuth();
  const clientId = user.client;
  const [status] = useState('1');
  const [searchStatus] = useState('1');
  const [customerFilterData]= useState([]);

  useEffect(() => {
    ListCustomers("");      
    ListAddress("");
  }, []);
  
  const columns = [    
    {
      title: 'Name',
      dataIndex: 'companyName',     
      width: '20%',        
      key: 'companyName',  
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.companyName.localeCompare(b.companyName)
    },
    {
      title: 'Contact',
      dataIndex: 'contacts',     
      key: 'contacts', 
      width: '20%',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.contacts.localeCompare(b.contacts)
    },   
    {
      title: 'Email',
      dataIndex: 'emailId',   
      key: 'emailId',   
      width: '20%',
    },
    {
      title: 'Phone',
      dataIndex: 'phNo',   
      key: 'phNo',   
      width: '20%',
    },      
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (record) => (     
        <Space size="middle">
        <Switch checked={record === 1 ? true : false}  disabled/>
        </Space>
      ),
    },
    {
      title: 'Edit',
      key: 'action',
      width: '5%',
      render: (_, record) => (
        <Space size="middle">
          <EditOutlined onClick={()=> {onEditCustomer(record);}} title="Edit" style={{color:'green'}}></EditOutlined>
        </Space>
      ),
    },
    
    {
      title: 'Delete',
      key: 'action',
      width: '5%',
      render: (_, record) => (
        <Space size="middle"> 
        <Popconfirm   title="Delete the customer" description="Are you sure to delete this customer?"
        onConfirm={() => handleDelete(record)} onCancel={cancel} okText="Yes" cancelText="No">
        <DeleteOutlined title="Delete" style={{color:'red'}}/> 
        </Popconfirm>
        </Space>
      ),
    }, 
  ];



  const columnAddress = [    
    {
      title: 'Address',
      dataIndex: 'address',     
      width: '70%',  
      editable: true, 
    },
    {
      title: 'Edit',
      dataIndex: 'Edit',
      render: (_, record) => {
        const editable = isEditing1(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => saveEditAddress(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </Typography.Link>
        );
      },
    }
  ]

  const mergedColumns = columnAddress.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing1(record),
      }),
    };
  });

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

const [data, setData] = useState(addresses);
const [editingKey, setEditingKey] = useState('');
const isEditing1 = (record) => record.key === editingKey;
const cancel = () => {
  setEditingKey('');
};
const [formAddress] = Form.useForm();  
const [formAddAddress]= Form.useForm();  
const edit = (record) => {
  formAddress.setFieldsValue({
    address: '',
    ...record,
  });
  setEditingKey(record.key);
};
const saveEditAddress = async (key) => {
  try {
    const rowCustomer = await form.validateFields();
    let companyId = rowCustomer.CustomerKey;

    const row = await formAddress.validateFields();
    const newData = [...data];
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
     
      let param  = `${key}`; 
      const custDocRef = doc(firestore, clientId, "address");
      setDoc(custDocRef, { [companyId] : {[param] : row.address}  },{ merge: true });     
      //setData(newData);
      setEditingKey('');
    } else {
      newData.push(row);     
      let param  = `${key}`; 
      const custDocRef = doc(firestore, clientId, "address");
      setDoc(custDocRef, { [companyId] : {[param] : row.address}  },{ merge: true });     
      //setData(newData);
      setEditingKey('');      
    }
    
    ListAddress(companyId); 
    msg.open({type: 'success', content: "Address has been updated successfully."});       
    
  } catch (errInfo) {
    console.log('Validate Failed:', errInfo);
    msg.open({type: 'error', content: errInfo.message});       
  }
};

const handleDelete = (record) => {
  console.log("record.key=>",record.key);
  try
  {
     //Delete Customer data in 'lookups' document
     const termDocRef = doc(firestore, clientId, "lookups");
     setDoc(termDocRef, { 'customersMap' : {[record.key] : deleteField() }},{ merge: true });  

      //Update Customer data in 'customers' collection
      deleteDoc(doc(firestore, `/${clientId}/details/customers`, record.key ));
      //updateDoc(doc(firestore, `/${clientId}/details/customers`,record.key ), {   
      //status:  2,
      //});

    msg.open({type: 'success', content: 'Company details has been deleted successfully.'});      
    handleCancel();
  } catch (error) {
    msg.open({type: 'error', content: error.message});           
  }
}

//Edit Link onClick method
const onEditCustomer =  (record) => {    
  setIsEditing(true);     
  setEditingCustomer({...record}); 
  form.setFieldsValue({ CustomerKey: record.key });
  form.setFieldsValue({ CompanyName: record.companyName });
  form.setFieldsValue({ Contact: record.contacts });
  //form.setFieldsValue({ Address: record.address });
  form.setFieldsValue({ EmailId: record.emailId });
  //form.setFieldsValue({ PrePhone: record.phPrefix });
  form.setFieldsValue({ Phone: record.phNo });
  form.setFieldsValue({ Status:record.status});

  ListAddress(record.key);      
};

const ListAddress = async (addressId) =>{
  try
  {
    if(addressId != ""){
      const q = query(collection(firestore, `/${clientId}/details/customers`));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
      });

      getDoc(doc(firestore, clientId, "address")).then(docSnap => {
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          var custData  =  docSnap.data();
          let addressData = [];

          const entries = Object.entries(custData);
          entries.forEach(([key, value]) =>  
          {
            let id =`${key}`
            if(addressId== id){         
              const entry = Object.entries(value);
                entry.forEach(([key1, value1]) =>  {             
                  // addressData.push( `${key1} = ${value1}`);
                  const address = {};
                  address['key'] = `${key1}`; 
                  address['address'] =  `${value1}`; 
                  addressData.push(address); 
                })
              }          
            }
          );
          //console.log(addressData); 
          setAddresses(addressData);    
        } else {
          msg.open({type: 'warning', content: 'No address found.'});      
        }
      });
    }
  } catch(error) {
    msg.open({
      type: 'error',
      content: error.message,
      duration: 10
    });
  }   
}
 
const handleOk = (e) => {
  //console.log(e);
  setIsEditing(false);
};

const handleCancel = (e) => {
  //console.log(e);
  setIsEditing(false);
};  

const onFormLayoutChange = ( ) => {
  //setFormLayout(layout);
};

const [form] = Form.useForm();  
 
  //Popup
  const [isModalOpen, setIsModalOpen] = useState(false);   
  const showModal = () => {
    form.resetFields();
    setIsModalOpen(true);
  };
  
  const handleOkNew = (data) => {   
    setIsModalOpen(false);
  };
  
  const handleCancelNew = () => {
    setIsModalOpen(false);
  };

  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Customer details has been updated successfully.',
    });
  };

  //Edit Popup - Save button onclick - Cusotmer Details to Firebase
  const onSaveCustomer = (values) => {   
    try
    {
      updateDoc(doc(firestore, `/${clientId}/details/customers`,values.CustomerKey ), {      
        companyId: values.CustomerKey,
        companyName: values.CompanyName,
        contacts: values.Contact,
        //address: values.Address,
        emailId: values.EmailId,
        //phPrefix: values.PrePhone,
        phNo: values.Phone,
        status: values.Status,
      });

      if(values.Status ===1){
        //Save Customer  name into the 'lookups' document
        const cusDocRef = doc(firestore, clientId, "lookups");
        setDoc(cusDocRef, { 'customersMap' : {[values.CustomerKey] : values.CompanyName}  },{ merge: true });  
      }else{
        const termDocRef = doc(firestore, clientId, "lookups");
        setDoc(termDocRef, { 'customersMap' : {[values.CustomerKey] : deleteField() }},{ merge: true });  
      }

      msg.open({type: 'success', content: 'Company details has been saved successfully.'});      
      handleCancel();
    } catch (error) {
      msg.open({type: 'error', content: error.message});           
    }
  };

  const onSaveNewAddress = async (values) => {
    try
    {      
      let companyId = await form.getFieldValue('CustomerKey');
      var today = new Date(); 
      var date = today.getFullYear() +''+ (today.getMonth() + 1) +''+ today.getDate() +''+ today.getHours() +''+ today.getMinutes() +''+ today.getSeconds();
      
      let param  = `${date}`; 
           
      const custDocRef = doc(firestore, clientId, "address");
      setDoc(custDocRef, {[companyId] : {[param] : values.AddAddress}}, {merge: true});     
       
      msg.open({type: 'success', content: 'Company address has been added successfully.'});      
      setDp(!dp)
      formAddAddress.setFieldValue('AddAddress','');
      ListAddress(companyId);      
     // handleCancel();
    } catch (error) {
      msg.open({type: 'error', content: error.message});           
    }
  };
 
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    /*number: {
      range: '${label} must be between ${min} and ${max}',
    },*/
  };

  const ListCustomers = (sInput) => {
  try 
  {  
    let srhText =sInput.srchText;
    let srhStatus = sInput.srchStatus;
    //console.log(srhText , srhStatus);
    const qry = query(collection(firestore, `/${clientId}/details/customers`));    
    const unsubscribe = onSnapshot(qry, (snapshot) => {
    
      if(snapshot.docs) {       
          const customerData = [];   
          snapshot.forEach((doc) => {        
            //console.log(doc.id, " => ", doc.data());
            const customer = {};
            const filCustomers = doc.data();            
              customer['key'] = doc.id;           
              customer['companyName'] =   filCustomers.companyName;
              customer['contacts'] =   filCustomers.contacts;
              customer['address'] = filCustomers.address;
              customer['emailId'] =   filCustomers.emailId;
              //customer['phPrefix'] =   filCustomers.phPrefix;
              customer['phNo'] =   filCustomers.phNo;
              customer['status'] =  filCustomers.status; 
              customerData.push(customer);                      
          });
 
          //console.log("filteredCustomers =>",  filteredCustomers);    
          let customerFilterData=customerData;

          if(srhText !== undefined) {
            customerFilterData = customerData.filter(
              customer => {
                return (
                  typeof customer.companyName === "string" && customer.companyName.toLowerCase().includes(srhText.toLowerCase()) ||
                  typeof customer.contacts === "string" && customer.contacts.toLowerCase().includes(srhText.toLowerCase()) ||
                  typeof customer.emailId === "string" && customer.emailId.toLowerCase().includes(srhText.toLowerCase()) ||
                  typeof customer.phNo === "string" && customer.phNo.toLowerCase().includes(srhText.toLowerCase())
                );
              }
            );
          }    

          if(srhStatus === -1){
            customerFilterData = customerFilterData.filter(
              customer => {
                return (
                  typeof customer.status === "number" && customer.status ===  1 ||
                  typeof customer.status === "number" && customer.status === 0
                );
              }
            );
          } 

          if(srhStatus === 1 || srhStatus === 0){
            customerFilterData = customerFilterData.filter(
              customer => {
                return (
                  typeof customer.status === "number" && customer.status === srhStatus
                );
              }
            ); 
          }
          
          //console.log("customerFilterData => ",  customerFilterData);
          setCustomers(customerFilterData);                
        } else {
          msg.open({type: 'warning', content: 'No customer found.'});
        }
      },(error) => {         
        msg.open({type: 'error', content: error.message});           
      },
    ); 
    return unsubscribe;
  } catch(error) {
      msg.open({ type: 'error', content: error.message,  duration: 10 });
  }
}
  
  const SearchCustomers = (e) => { 
   
    let srchText = e[0].value;
    let srchStatus = e[1].value;
    const SearchInput = {
      srchText: srchText,
      srchStatus: srchStatus
    }
    ListCustomers(SearchInput);    
  }

 const { Option } = Select;
  const prefixSelector = (
    <Form.Item name="PrePhone" noStyle>
      <Select style={{width: 70,}}>        
        <Option value="+1">+1</Option>
      </Select>
    </Form.Item>
  );

  
const cardstyle = {
  width:'100%',
}

const inputStyle= {
  width:'300px',
}


const divStyle = { 
  width:'100%',
  float: 'left',
}

const divLeft = {
  float: 'left',
  width:'80%',
}


const divRight = {
  float: 'right',
}

const hideTxtBx = {
  display: 'none',
}

const { Search } = Input; 

const [frmAddAdresStyle, setAddAdresKey] = useState('');
const [dp, setDp] = useState(false);
//setAddAdresKey('display:none');

return (
      <Row gutter={{
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
      }}>        
       {msgContextHolder}       
       <Card title="" style={cardstyle}>          
          <div style={cardstyle}>
            <div style={divStyle}>
                <div style={divLeft}>
                <Form form={form} name="frmSearch"  layout="inline" initialValues={{ SearchStatus: -1}} 
              onFieldsChange={(_, allFields) => {  SearchCustomers(allFields); }}>
                <Form.Item label="" name="SearchWord" style={inputStyle} >
                 <Search placeholder="Search by Name, Person, Email & Phone" allowClear maxLength={20} />
                </Form.Item>
                <Form.Item label="" name="SearchStatus" >
                  <Radio.Group  value={searchStatus} optionType="button" buttonStyle="solid">
                  <Radio value={1}>Active</Radio>
                    <Radio value={0}>Inactive</Radio>
                    <Radio value={-1}>All</Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
                </div>
                <div style={divRight}>
                <Button type="primary"  icon={<PlusSquareOutlined />} onClick={showModal}>Customer</Button>
                </div>
            </div>
          </div>
          <div>&nbsp;</div>  
          <div style={divStyle}>
            <Table rowKey={customers.key} dataSource={customers} columns={columns}  size="small" pagination={{ pageSizeOptions: ["10", "20","30", "40","50"], pageSize:10, showSizeChanger:false, defaultPageSize: 10 }}></Table>
          </div>
        </Card>
     
        <ModalForm open={isModalOpen} onCancel={handleCancelNew} />        
        <Modal  
          title="Edit Customer & Addresses" 
          centered 
          open={isEditing} 
          okText="Save Changes" 
          onOk={handleOk} 
          onCancel={handleCancel} 
          maskClosable={false}
          footer={ null} >
          <Form form={form} layout="horizontal" labelCol={{span: 8}} wrapperCol={{span: 16}} autoComplete="off" onValuesChange={onFormLayoutChange}
            initialValues={{
              CustomerKey: editingCustomer?.key,
              CompanyName: editingCustomer?.CompanyName,
              Contact: editingCustomer?.Contact,              
              EmailId: editingCustomer?.EmailId,
              Phone: editingCustomer?.Phone,
              Status: editingCustomer?.Status,
            }} 
            onFinish={onSaveCustomer} validateMessages={validateMessages}>
            <Form.Item label="Key" name="CustomerKey" style={hideTxtBx}><Input disabled={true}  /></Form.Item>
            <Form.Item label="Company Name" name="CompanyName" rules={[{ required: true, message: 'Please input your company name!' }]} >
            <Input  />
            </Form.Item>
            <Form.Item  label="Contact Person" name="Contact" rules={[{ required: true, message: 'Please input your contact!' }]}>
            <Input/>
            </Form.Item>            
            <Form.Item label="Email" name="EmailId" rules={[{ required: true, type: 'email' }]} help="ex. name@domain.com">
            <Input/>
            </Form.Item>
            <Form.Item label="Phone" name="Phone" help="ex. +15717894187">
            <Input  />
            </Form.Item>    
            <Form.Item label="Status" name="Status"  rules={[{ required: true, message: 'Please select the customer status!' }]}>
                <Radio.Group value={status} optionType="button" buttonStyle="solid">
                  <Radio value={1}>Active</Radio>
                  <Radio value={0}>Inactive</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item>          
            <Button key="back" onClick={handleCancel}>Cancel</Button>&nbsp;&nbsp;&nbsp;
            <Button key="submit"  type="primary" htmlType="submit">Save</Button>         
            </Form.Item>  
          </Form>
          
          <Form form={formAddress} component={false}  >         
          <Table rowKey={addresses.key} dataSource={addresses} columns={mergedColumns}  size="small"
           pagination={{ pageSize:3, showSizeChanger:false, 
           defaultPageSize: 10 }} rowClassName="editable-row" components={{ body: { cell: EditableCell },}}></Table>          
         </Form>

         <Button  type="primary"  onClick={() => setDp(!dp)}  style={{ marginBottom: 16 }}>Add Address</Button>
        {dp &&  <div>
         <Form form={formAddAddress}  layout="horizontal"  onFinish={onSaveNewAddress}>        
          <Form.Item label="Address" name="AddAddress" rules={[{ required: true, message: 'Please enter your company address!' }]} >
            <Input  />
            </Form.Item>
            <Form.Item>          
            <Button key="back" onClick={() => setDp(!dp)}>Cancel</Button>&nbsp;&nbsp;&nbsp;
            <Button key="submit"  type="primary" htmlType="submit">Save</Button>         
            </Form.Item>  
         </Form></div>
        }
          {contextHolder}
      </Modal>
    </Row>      
    ); 
};


//New Customer Form.
// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({ form, open }) => {
  const prevOpenRef = useRef();
  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;
  useEffect(() => {
    if (!open && prevOpen) {
      form.resetFields();
    }
  }, [form, prevOpen, open]);
};

//const [status, setStatus] = useState('1'); 
const { Option } = Select;

const prefixSelector = (
  <Form.Item name="phPrefix" noStyle>
    <Select style={{width: 70,}}>        
      <Option value="+1">+1</Option>
    </Select>
  </Form.Item>
);
const ModalForm = ({ open, onCancel }) => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  useResetFormOnCloseModal({
    form,
    open,
  });
  const onOk = () => {       
    form.submit();    
  };

  useEffect(() => {
    ListCustomers('');
  } , [messageApi]);

  const { user } = useAuth();
  const clientId = user.client;

  const onFinish = (values) => { 
    try
    {
      const isExist = checkExists(values.CompanyName);
      if(isExist )
      {
        let companyId = generateId(20);

        //Save Address to address collection
        var today = new Date(); 
        var date = today.getFullYear() +''+ (today.getMonth() + 1) +''+ today.getDate() +''+ today.getHours() +''+ today.getMinutes() +''+ today.getSeconds();
        
        let param  = `${date}`; 
             
        const custDocRef = doc(firestore, clientId, "address");
        setDoc(custDocRef, { [companyId] : {[param] : values.Address}  },{ merge: true });     
        //updateDoc(custDocRef, {myObject});

        setDoc(doc(firestore, `/${clientId}/details/customers`,companyId ), {
          companyId: companyId,
          companyName: values.CompanyName,
          contacts: values.Contact,
          address: values.Address,
          emailId: values.EmailId,
          //phPrefix: values.phPrefix,
          phNo: values.Phone,
          status: values.Status,
        });      
        
        //Save Customer  name into the 'lookups' document
        const cusDocRef = doc(firestore, clientId, "lookups");
        setDoc(cusDocRef, { 'customersMap' : {[companyId] : values.CompanyName}  },{ merge: true });  
 
        success();
        onCancel();
      }else{        
        let message = "Customer ("+ values.CompanyName + ") is exist, please enter different customer details!"
        messageApi.open({type: 'error', content: message});        
      }
    } catch  (error){
      messageApi.open({type: 'error', content: error.message});       
    }
  };

  const [customerSearch, setCustomers] = useState([]);
  const ListCustomers = () => {
    try {
      const qry = query(collection(firestore, `/${clientId}/details/customers`)); 
      onSnapshot(qry, (snapshot) =>  {    
        if(snapshot.docs) {       
            const customerData = [];   
            snapshot.forEach((doc) => {        
               //console.log(doc.id, " => ", doc.data());
              const customer = {};
              customer['key'] = doc.id;              
              const filCustomers = doc.data();
              customer['companyName'] =   filCustomers.companyName;          
              customerData.push(customer);           
            });
  
          setCustomers(customerData);
          //console.log(  "customerData => ", customerData);
        }       
      });
     } catch (error) {
      messageApi.open({type: 'error', content: error.message}); 
    }
  };
 

function checkExists(custName) {
  let retCust = true; 
  try
  {
    //console.log(  "customerSearch => ", customerSearch);
    let customersFilterData = customerSearch.filter(
      customer => {
        return (
          typeof customer.companyName === "string" && customer.companyName.toLowerCase().includes(custName.toLowerCase())
        );
      }
    ); 
    
    let cntCusts = customersFilterData.length; 
    if(cntCusts > 0)
    {
      retCust = false; 
    } 
  } catch (error) {
    messageApi.open({type: 'error', content: error.message}); 
  }    
  return retCust; 
}

 
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Customer details has been added successfully.',
    });
  };
  const error = () => {
    messageApi.open({
      type: 'error',
      content: 'This is an error message',
    });
  };
  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'This is a warning message',
    });
  };

function generateId(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
} 

const [status] = useState('1');
const [value, setValue] = useState(1);

return (   
    <Modal 
      title="New Customer" 
      open={open} 
      onOk={onOk} 
      onCancel={onCancel}
      centered  
      maskClosable={false}
      footer={[
        <Button key="back" onClick={onCancel}>Cancel</Button>,
        <Button key="submit" type="primary" htmlType="submit" onClick={onOk}>Save</Button>
      ]} 
      >
       <Form form={form} name="frmNewCustomer"  onFinish={onFinish} layout="horizontal" labelCol={{span: 8}} wrapperCol={{span: 16}}
       initialValues={{ Status: 1, }} >
          <Form.Item label="Company Name" name="CompanyName" rules={[{ required: true, message: 'Please enter your company name!' }]} >
          <Input/>
        </Form.Item>
          <Form.Item  label="Contact Person" name="Contact" rules={[{ required: true, message: 'Please enter your contact person!' }]}>
          <Input/>
        </Form.Item>
        <Form.Item  label="Address" name="Address" rules={[{ required: true, message: 'Please enter company address!' }]}>
            <Input/>
        </Form.Item>
        <Form.Item label="Email" name="EmailId" rules={[{ required: true, type: 'email' }]} help="ex. name@domain.com">
          <Input/>
        </Form.Item>
        <Form.Item label="Phone" name="Phone" rules={[{ required: true, type:'string',len: 12 }]} help="ex. +15717894187">
            <Input  />
        </Form.Item>    
        <Form.Item label="Status" name="Status"  rules={[{ required: true, message: 'Please select the customer status!' }]}>
          <Radio.Group  value={value} optionType="button" buttonStyle="solid">
            <Radio value={1}>Active</Radio>
            <Radio value={0}>Inactive</Radio>
          </Radio.Group>
        </Form.Item>
        </Form>
        {contextHolder}
    </Modal>
  );
};

export default CustomersList; 