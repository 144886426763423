import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Button, Modal } from 'antd';
import { useState } from 'react';

const Footer = () => {

  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);
  const showSupport = () => {
    setIsSupportModalOpen(true);
  };

  const [isHelpCenterModalOpen, setIsHelpCenterModalOpen] = useState(false);
  const showHelpCenter = () => {
    setIsHelpCenterModalOpen(true);
  };

  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const showPrivacy = () => {
    setIsPrivacyModalOpen(true);
  };

  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const showTerms= () =>{
    setIsTermsModalOpen(true);
  }


  const handleOk = () => {
    setIsSupportModalOpen(false);
    setIsHelpCenterModalOpen(false);
    setIsPrivacyModalOpen(false);
    setIsTermsModalOpen(false);
  };
  const handleCancel = () => {
    setIsSupportModalOpen(false);
    setIsHelpCenterModalOpen(false);
    setIsPrivacyModalOpen(false);
    setIsTermsModalOpen(false);
  };
  
  return (
    <><footer className="footer">
    <Container fluid>
      <Row className="text-muted">
        <Col xs="6" className="text-start">
          <ul className="list-inline">
            <li className="list-inline-item">
              <span className="text-muted" href="#">
                <a href="#" onClick={showSupport} title="Support">Support</a>
              </span>
            </li>
            <li className="list-inline-item">|</li>
            <li className="list-inline-item">
              <span className="text-muted" href="#">
              <a href="#" onClick={showHelpCenter} title="Help Center">Help Center</a>
                
              </span>
            </li> <li className="list-inline-item">|</li>
            <li className="list-inline-item">
              <span className="text-muted" href="#">
              <a href="#" onClick={showPrivacy} title="Privacy">Privacy</a>
                
              </span>
            </li> <li className="list-inline-item">|</li>
            <li className="list-inline-item">
              <span className="text-muted" href="#">
              <a href="#" onClick={showTerms} title="Terms of Service">Terms of Service</a>                
              </span>
            </li>
          </ul>
        </Col>
        <Col xs="6" className="text-end">
          <p className="mb-0">
            &copy; {new Date().getFullYear()} -{" "}
            <span href="/" className="text-muted">
            <a href="https://www.talentmindsllc.com/?id=fleetsuite" target="_blank" title="Talent Minds LLC">Talent Minds</a> - Fleet Suite
            </span>
          </p>
        </Col>
      </Row>
    </Container>
  </footer>

  <Modal title="Support" open={isSupportModalOpen} onOk={handleOk} okText='Close' onCancel={handleCancel} centered width={1000} footer={null}>
    <div>
   <br></br>
   Support - Talent Minds LLC ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used, and disclosed by us.
    This Privacy Policy applies to our websites and apps, and its associated subdomains (collectively, our "Service") alongside our applications. By accessing or using our Service, you signify that you have read, understood, and agree to our collection, storage, use, and disclosure of your personal information as described in this Privacy Policy and our Terms of Service.
    <br></br><br></br>
    <h4>Information automatically collected</h4>
    <br></br><br></br>
    There is some information like your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our platform. This information may be used to connect your computer to the Internet. Other information collected automatically could be a login, e-mail address, password, computer and connection information such as browser plug-in types and versions and time zone setting, operating systems and platforms, purchase history, (we sometimes aggregate with similar information from other Users), the full Uniform Resource Locator (URL) clickstream to, through and from our Website that may include date and time; cookie number; parts of the site you viewed or searched for; and the phone number you used to call our Customer Services. We may also use browser data such as cookies, Flash cookies (also known as Flash Local Shared Objects) or similar data on certain parts of our Website for fraud prevention and other purposes. During your visits, we may use software tools such as JavaScript to measure and collect session information including page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away from the page. We may also collect technical information to help us identify your device for fraud prevention and diagnostic purposes.
    <br></br><br></br>
    We automatically collect certain information when you visit, use or navigate the platform. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about who and when you use our and other technical information. This information is primarily needed to maintain the security and operation of our , and for our internal analytics and reporting purposes.
    </div>    
  </Modal>

  <Modal title="Help Center" open={isHelpCenterModalOpen} onOk={handleOk} okText='Close' onCancel={handleCancel} centered width={1000} footer={null}>
    <div>
   <br></br>
    Help Center - Talent Minds LLC ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used, and disclosed by us.
    This Privacy Policy applies to our websites and apps, and its associated subdomains (collectively, our "Service") alongside our applications. By accessing or using our Service, you signify that you have read, understood, and agree to our collection, storage, use, and disclosure of your personal information as described in this Privacy Policy and our Terms of Service.
    <br></br><br></br>
    <h4>Information automatically collected</h4>
    <br></br><br></br>
    There is some information like your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our platform. This information may be used to connect your computer to the Internet. Other information collected automatically could be a login, e-mail address, password, computer and connection information such as browser plug-in types and versions and time zone setting, operating systems and platforms, purchase history, (we sometimes aggregate with similar information from other Users), the full Uniform Resource Locator (URL) clickstream to, through and from our Website that may include date and time; cookie number; parts of the site you viewed or searched for; and the phone number you used to call our Customer Services. We may also use browser data such as cookies, Flash cookies (also known as Flash Local Shared Objects) or similar data on certain parts of our Website for fraud prevention and other purposes. During your visits, we may use software tools such as JavaScript to measure and collect session information including page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away from the page. We may also collect technical information to help us identify your device for fraud prevention and diagnostic purposes.
    <br></br><br></br>
    We automatically collect certain information when you visit, use or navigate the platform. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about who and when you use our and other technical information. This information is primarily needed to maintain the security and operation of our , and for our internal analytics and reporting purposes.
    </div>    
  </Modal>

  <Modal title="Privacy Policy" open={isPrivacyModalOpen} onOk={handleOk} okText='Close' onCancel={handleCancel} centered width={1000} footer={null}>
    <div>
   <br></br>
    Privacy PolicyTalent Minds LLC ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used, and disclosed by us.
    This Privacy Policy applies to our websites and apps, and its associated subdomains (collectively, our "Service") alongside our applications. By accessing or using our Service, you signify that you have read, understood, and agree to our collection, storage, use, and disclosure of your personal information as described in this Privacy Policy and our Terms of Service.
    <br></br><br></br>
    <h4>Information automatically collected</h4>
    <br></br><br></br>
    There is some information like your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our platform. This information may be used to connect your computer to the Internet. Other information collected automatically could be a login, e-mail address, password, computer and connection information such as browser plug-in types and versions and time zone setting, operating systems and platforms, purchase history, (we sometimes aggregate with similar information from other Users), the full Uniform Resource Locator (URL) clickstream to, through and from our Website that may include date and time; cookie number; parts of the site you viewed or searched for; and the phone number you used to call our Customer Services. We may also use browser data such as cookies, Flash cookies (also known as Flash Local Shared Objects) or similar data on certain parts of our Website for fraud prevention and other purposes. During your visits, we may use software tools such as JavaScript to measure and collect session information including page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away from the page. We may also collect technical information to help us identify your device for fraud prevention and diagnostic purposes.
    <br></br><br></br>
    We automatically collect certain information when you visit, use or navigate the platform. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about who and when you use our and other technical information. This information is primarily needed to maintain the security and operation of our , and for our internal analytics and reporting purposes.
    </div>    
  </Modal>

  <Modal title="Terms of Service" open={isTermsModalOpen} onOk={handleOk} okText='Close' onCancel={handleCancel} centered width={1000} footer={null}>
    <div>
   <br></br>
   Terms of Service - Talent Minds LLC ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used, and disclosed by us.
    This Privacy Policy applies to our websites and apps, and its associated subdomains (collectively, our "Service") alongside our applications. By accessing or using our Service, you signify that you have read, understood, and agree to our collection, storage, use, and disclosure of your personal information as described in this Privacy Policy and our Terms of Service.
    <br></br><br></br>
    <h4>Information automatically collected</h4>
    <br></br><br></br>
    There is some information like your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our platform. This information may be used to connect your computer to the Internet. Other information collected automatically could be a login, e-mail address, password, computer and connection information such as browser plug-in types and versions and time zone setting, operating systems and platforms, purchase history, (we sometimes aggregate with similar information from other Users), the full Uniform Resource Locator (URL) clickstream to, through and from our Website that may include date and time; cookie number; parts of the site you viewed or searched for; and the phone number you used to call our Customer Services. We may also use browser data such as cookies, Flash cookies (also known as Flash Local Shared Objects) or similar data on certain parts of our Website for fraud prevention and other purposes. During your visits, we may use software tools such as JavaScript to measure and collect session information including page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away from the page. We may also collect technical information to help us identify your device for fraud prevention and diagnostic purposes.
    <br></br><br></br>
    We automatically collect certain information when you visit, use or navigate the platform. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about who and when you use our and other technical information. This information is primarily needed to maintain the security and operation of our , and for our internal analytics and reporting purposes.
    </div>    
  </Modal>
  </>
  );
};

export default Footer;
