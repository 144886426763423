
import { React, useEffect, useState } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement,LineElement, Title,Tooltip, Legend,} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { Card, Col, Row } from 'react-bootstrap';
import { faker } from '@faker-js/faker';
import Chart from 'chart.js/auto';
import { collection, onSnapshot, query,doc, getDoc  } from "firebase/firestore";
import { firestore } from "../../firebase/Firebase";
import useAuth from "../../hooks/useAuth";
import moment from "moment/moment";
import { message } from "antd";

const WorkorderChart = () => {   
  const { user } = useAuth();
  const [msg, msgContextHolder] = message.useMessage();
  const clientId = user.client;
  const dateFormat = 'DD-MMM-YYYY';  
  const [workorders, setWorkorders] = useState([]);
  
  const [lastTenDates, setLastTenDates] = useState([]);
  const [lastFiveDates, setLastFiveDates] = useState([]);

  const [cnt5Completed, setCompleted] = useState([]);
  const [cnt5Assigned, setAssigned] = useState([]);
  const [cnt5Unassigned, setUnassigned] = useState([]);
  const [cnt10Data, setLast10Data] = useState([]);
  
  
  useEffect(() => {
    getLast10Days();
    getLast5Days();
    ListWorkOrders("");  

    ChartJS.register(CategoryScale,LinearScale,BarElement,PointElement,LineElement,Title,Tooltip,Legend);
  } , [clientId, msg]);


  const ListWorkOrders = (sInput) => {
    try { 
      const qry = query(collection(firestore, `/${clientId}/details/workorders`));  
      const unsubscribe = onSnapshot(qry, (snapshot) => {
        if(snapshot.docs)
        {
          const workorders = [];   
          const orders = [];  
          snapshot.forEach((doc) => {     
              const workorder = {};
              workorder['key'] = doc.id;              
              const filWo = doc.data();         
              workorder['BOL'] =   filWo.bol;
              workorder['CustomerName'] = filWo.customer;
              workorder['TerminalName'] = filWo.terminal;
              workorder['DeliveryDate'] =  filWo.delDate[0];
              workorder['DriverName'] = filWo.driver;    
              workorder['SplitCnt'] = filWo.splitcnt;                
              workorder['Status'] =  filWo.woStatus; 
              workorder['order'] = orders;
              workorders.push(workorder);     
          });
          //console.log('workorders<>',workorders);

          //Last 5 days data
          const resultDates = [];
          const current = moment();
          let n=5
          while(n>0){            
              resultDates.push(current.format(dateFormat))
              current.subtract(1,"day")
              n--;           
          }

          var arr5Completed = [];  
          var arr5Assigned = [];
          var arr5Unassigned = [];

          resultDates.forEach((dte) => { 
           let woFilterCData = workorders.filter(
              customer => {
                return (
                  moment(customer.DeliveryDate, dateFormat).isSame(moment(dte, dateFormat)) && 
                  typeof customer.Status === "string" &&  customer.Status.toLowerCase().includes('completed') 
                );
              }
            );             
            //console.log("Completed: dte>",dte, '<Count>', woFilterCData.length);
            arr5Completed.push(woFilterCData.length);

          let  woFilterAData = workorders.filter(
              customer => {
                return (
                  moment(customer.DeliveryDate, dateFormat).isSame(moment(dte, dateFormat)) && 
                  typeof customer.Status === "string" &&  customer.Status.toLowerCase() === 'assigned' 
                );
              }
            );         
            //console.log("Assigned: dte>",dte, '<Count>', woFilterAData.length);
            arr5Assigned.push(woFilterAData.length);

            let woFilterUData = workorders.filter(
              customer => {
                return (
                  moment(customer.DeliveryDate, dateFormat).isSame(moment(dte, dateFormat)) && 
                  typeof customer.Status === "string" &&  customer.Status.toLowerCase() === 'unassigned'
                );
              }
            ); 
            //console.log("Unassigned: dte>",dte, '<Count>', woFilterUData.length);
            arr5Unassigned.push(woFilterUData.length);
          });        
          setCompleted(arr5Completed);
          setAssigned(arr5Assigned);
          setUnassigned(arr5Unassigned); 
          
          //Last 10days Performance
          const currentLp = moment();
          const result10Days = [];
          let x=10
          while(x>0){            
            result10Days.push(currentLp.format(dateFormat))
            currentLp.subtract(1,"day")
              x--;           
          }
          //console.log('result10Days<>',result10Days);
          var arr10Days = [];  
          result10Days.forEach((lpdte) => { 
            let woFilterCData = workorders.filter(
               customer => {
                 return (
                   moment(customer.DeliveryDate, dateFormat).isSame(moment(lpdte, dateFormat))
                 );
               }
             );             
             //console.log("Completed: dte>",lpdte, '<Count>', woFilterCData.length);
             arr10Days.push(woFilterCData.length);
          });   
          setLast10Data(arr10Days);
         // console.log('arr10Days>',arr10Days);
        }
        else
        {
          msg.open({type: 'warning', content: 'No workorder found.'});
        }
      },(error) => {         
        msg.open({type: 'error', content: error.message});        
      },
      );
      return unsubscribe;
    } catch(error) {
      msg.open({
        type: 'error',
        content: error.message,
        duration: 10
      });
    }      
  }
  
  const getLast10Days = () => {
    try 
    {       
      const resultDates = [];
      const current = moment();
      let n=10
      while(n>0){            
          resultDates.push(current.format(dateFormat))
          current.subtract(1,"day")
          n--;           
      }
      setLastTenDates(resultDates);    
    } catch(error) {
      msg.open({
        type: 'error',
        content: error.message,
        duration: 10
      });
    }      
  }

  const getLast5Days = () => {
    try 
    {        
      const resultDates = [];
      const current = moment();
      let n=5
      while(n>0){            
          resultDates.push(current.format(dateFormat))
          current.subtract(1,"day")
          n--;           
      }
      setLastFiveDates(resultDates);
    
    } catch(error) {
      msg.open({
        type: 'error',
        content: error.message,
        duration: 10
      });
    }      
  }


  //By status
  const chartByStatus = () => {
    try 
    {     
      

    } catch(error) {
      msg.open({
        type: 'error',
        content: error.message,
        duration: 10
      });
    }      
  }

//Last 10days
const chartByLast10Days = () => {
  try 
  {    
    
  }
  catch(error) {
    msg.open({
      type: 'error',
      content: error.message,
      duration: 10
    });
  }      
}


//By status
const barOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'By Status',
    },
  },
};

var labels = lastFiveDates;// ['05/14', '05/15', '05/16', '05/17', '05/18'];
var completedData = cnt5Completed; //[10,20,30,40,10];
var assignedData = cnt5Assigned;// [10,20,30,40,10];
var unassignedData = cnt5Unassigned;// [10,20,30,40,10];
 
const barData = {
  labels,
  datasets: [
    {
      label: 'Completed',
      data: completedData, //labels.map(() => completedData),
      backgroundColor: 'rgba(75, 191, 115, 0.5)',
    },
    {
      label: 'Assigned',
      data: assignedData, //labels.map(() => faker.datatype.number({ min: 0, max: 75 })),
      backgroundColor: 'rgba(63, 128, 234, 0.5)',
    },
    {
      label: 'Unassigned',
      data: unassignedData,//labels.map(() => faker.datatype.number({ min: 0, max: 50 })),
      backgroundColor: 'rgba(217, 83, 79, 0.5)',
    },
  ],
};


//Last 10days
const lineOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Last 10 Days Performance',
    },
  },
};

labels = lastTenDates; //['05/08', '05/09', '05/10', '05/11', '05/12', '05/13', '05/14', '05/15', '05/16', '05/17'];
var lastTenDatesData = [10,20,290,40,10,15,20,30,33,100];
const lineData = {
labels,
datasets: [
  {
    label: 'Workorders',
    data: cnt10Data, //labels.map(() => faker.datatype.number({ min: 50, max: 300 })),
    borderColor: 'rgb(31, 155, 207)',
    backgroundColor: 'rgba(31, 155, 207, 0.5)',
  }
],
};

return (
  <Row>
      <Col md="12" xl className="d-flex">
          <Card className="flex-fill">              
              <Card.Body className="d-flex">
                  <div className="align-self-center w-100">
                      <div className="chart chart-lg">
                          <Bar options={barOptions} data={barData} />
                      </div>
                  </div>
              </Card.Body>
          </Card>
      </Col>
      <Col md="12" xl className="d-flex">
          <Card className="flex-fill">                    
              <Card.Body className="d-flex">
                  <div className="align-self-center w-100">
                      <div className="chart chart-lg">
                      <Line options={lineOptions} data={lineData} />
                      </div>
                  </div>
              </Card.Body>
          </Card>
      </Col>
      {msgContextHolder}        
  </Row>
  );
};

export default WorkorderChart;