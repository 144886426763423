import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import CustomersList from "../../components/customers/CustomersList";

const CustomersPage = () => (
    <React.Fragment>
        <Helmet title="Customers" />
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Customers</h1>
            <CustomersList />
        </Container>       
    </React.Fragment>
);

export default CustomersPage;