import { Card, Table,message,Row,Form,Button,Input,Radio, Select,Modal, Switch, Space,Popconfirm } from "antd";
import { CollectionReference, collection, onSnapshot, query, where, getDocs,getDoc,deleteField,deleteDoc   } from "firebase/firestore";
import { useEffect, useState,useRef } from "react";
import { firestore } from "../../firebase/Firebase";
import useAuth from '../../hooks/useAuth';
import { DeleteOutlined, EditOutlined, FileAddOutlined, FormOutlined, PlusSquareOutlined, SearchOutlined as SearchIcon } from '@ant-design/icons';
import { doc, setDoc,updateDoc } from "firebase/firestore"; 
import { ModalFooter } from "react-bootstrap";

const TerminalsList = () => {
  const [terminals, setTerminals] = useState([]);
  const [msg, msgContextHolder] = message.useMessage(); 
  const {user} = useAuth();
  const clientId = user.client;
  const [searchForm] = Form.useForm();  
  const [editForm] = Form.useForm();  

  const [searchStatus] = useState('1');

  const [editingTerminal, setEditingTerminal] = useState('');  
  const [isEditing, setIsEditing] = useState(false);

  const [status] = useState('1');

  useEffect(() => {
    ListTerminals("");      
  }, []);

  const ListTerminals = (sInput) => {
    try 
    {  
      let srhText =sInput.srchText;
      let srhStatus = sInput.srchStatus;
  
      const qry = query(collection(firestore, `/${clientId}/details/terminals`));    
      const unsubscribe = onSnapshot(qry, (snapshot) => {
      //console.log(snapshot.docs); 
      
      if(snapshot.docs) 
      {
          const terminalData = [];   
          snapshot.forEach((doc) => {        
            //console.log(doc.id, " => ", doc.data());
            const terminal = {};
            const filTerminal = doc.data();            
              terminal['key'] = doc.id;   
              terminal['terminalName'] =   filTerminal.terminalName;
              terminal['address'] =   filTerminal.address;
              terminal['contacts'] = filTerminal.contacts;
              terminal['emailId'] =   filTerminal.emailId;
              terminal['phPrefix'] =   filTerminal.phPrefix;
              terminal['phNo'] =   filTerminal.phNo;
              terminal['status'] =  filTerminal.status; 
              terminalData.push(terminal);                    
          }); 
          
          let filteredTerminals=terminalData;

          //const data = snapshot.docs.map(doc => doc.data());            
          //let filteredTerminals=data;
  
          if(srhText !== undefined) {
            filteredTerminals = terminalData.filter(
              terminal => {
                return (
                  (typeof terminal.terminalName === "string") && (terminal.terminalName.toLowerCase().includes(srhText.toLowerCase())) ||
                  typeof terminal.contacts === "string" && terminal.contacts.toLowerCase().includes(srhText.toLowerCase()) ||
                  typeof terminal.emailId === "string" && terminal.emailId.toLowerCase().includes(srhText.toLowerCase()) ||
                  typeof terminal.phNo === "string" && terminal.phNo.toLowerCase().includes(srhText.toLowerCase())
                );
              }
            );
          }    
  
          if(srhStatus === -1){
            filteredTerminals = filteredTerminals.filter(
              terminal => {
                return (
                  typeof terminal.status === "number" && terminal.status===1 ||
                  typeof terminal.status === "number" && terminal.status===0
                );
              }
            );  
          } 

          if(srhStatus === 1 || srhStatus === 0){
            filteredTerminals = filteredTerminals.filter(
              terminal => {
                return (
                  typeof terminal.status === "number" && terminal.status === srhStatus  
                );
              }
            ); 
          }
          //console.log("filteredTerminals => ",  filteredTerminals);
         
            setTerminals(filteredTerminals);                
          } else {
            msg.open({type: 'warning', content: 'No terminal found.'});
          }
        },(error) => {         
          msg.open({type: 'error', content: error.message});           
        },
      ); 
      return unsubscribe;
    } catch(error) {
        msg.open({ type: 'error', content: error.message,  duration: 10 });
    }
  }

  const handleDelete = (record) => {
    console.log("record.key=>",record.key);
    try
    {
      //Delete terminal data in 'lookups' document
      const termDocRef = doc(firestore, clientId, "lookups");
      setDoc(termDocRef, { 'terminalsMap' : {[record.key] : deleteField() }},{ merge: true });  

      //Update terminal data in 'terminals' collection
      deleteDoc(doc(firestore, `/${clientId}/details/terminals`, record.key));
      //updateDoc(doc(firestore, `/${clientId}/details/terminals`,record.key ), {   
       // status:  2,
      //});
      msg.open({type: 'success', content: 'Terminal details has been deleted successfully.'});      
      handleCancel();
    } catch (error) {
      msg.open({type: 'error', content: error.message});           
    }
  }

//Edit Link onClick method
const onEditTerminal = (record) => {  
  setIsEditing(true);     
  setEditingTerminal({...record}); 
  editForm.setFieldsValue({ key: record.key });
  editForm.setFieldsValue({ TerminalName: record.terminalName });
  editForm.setFieldsValue({ Address: record.address });
  editForm.setFieldsValue({ Contact: record.contacts });
  editForm.setFieldsValue({ EmailId: record.emailId });
  editForm.setFieldsValue({ PrePhone: record.phPrefix });
  editForm.setFieldsValue({ Phone: record.phNo });
  editForm.setFieldsValue({ Status:record.status});
};

 //Edit Popup - Save button onclick - Terminal Details to Firebase
 const onSaveTerminal = (values) => {
  try
  {
    if(values.Status ===1){
    const termDocRef = doc(firestore, clientId, "lookups");
    setDoc(termDocRef, { 'terminalsMap' : {[values.key] : values.TerminalName}  },{ merge: true });   
  }else{
    const termDocRef = doc(firestore, clientId, "lookups");
    setDoc(termDocRef, { 'terminalsMap' : {[values.key] : deleteField() }},{ merge: true });  
  }
    updateDoc(doc(firestore, `/${clientId}/details/terminals`,values.key ), {      
      terminalId: values.key,
      terminalName: values.TerminalName,
      address: values.Address,
      contacts: values.Contact,
      emailId: values.EmailId,
      //phPrefix: values.PrePhone,
      phNo: values.Phone,
      status: values.Status,
    });
    success();
    handleCancel();
  } catch  (error){
    msg.open({type: 'error', content: error.message});           
  }
};

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  /*number: {
    range: '${label} must be between ${min} and ${max}',
  },*/
};

const handleOk = (e) => {
  //console.log(e);
  editForm.submit();
  setIsEditing(false);
};

const handleCancel = (e) => {
  //console.log(e);
  setIsEditing(false);
};

const { Option } = Select;
  const prefixSelector = (
    <Form.Item name="PrePhone" noStyle>
      <Select style={{width: 70,}}>        
        <Option value="+1">+1</Option>
      </Select>
    </Form.Item>
  );


const [messageApi, contextHolder] = message.useMessage();
const success = () => {
  messageApi.open({
    type: 'success',
    content: 'Terminal details has been updated successfully.',
  });
};

//Search functionality
const SearchTerminals = (e) => { 
  //console.log(e);
  let srchText = e[0].value;
  let srchStatus = e[1].value;
  const SearchInput = {
    srchText: srchText,
    srchStatus: srchStatus
  }
  ListTerminals(SearchInput);    
}

//Popup
const [isModalOpen, setIsModalOpen] = useState(false);   

const showModal = () => {
  //searchForm.resetFields();
  setIsModalOpen(true);
};

const handleCancelNew = () => {
  setIsModalOpen(false);
};

const columns = [  
  {
    title: 'Name',
    dataIndex: 'terminalName',   
    width: '20%',
    key:  'terminalName',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.terminalName.localeCompare(b.terminalName)
  },
  {
    title: 'Address',
    dataIndex: 'address', 
    width: '20%',  
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.address.localeCompare(b.address)
  }, 
  {
    title: 'Contact', 
    width: '20%',
    dataIndex: 'contacts',    
  },
  
  {
    title: 'Email',
    dataIndex: 'emailId',      
    width: '20%',
  },
  {
    title: 'Phone',
    dataIndex: 'phNo',
    width: '20%',
  },      
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '10%',
    render: (record) => (     
      <Space size="middle">
      <Switch checked={record === 1 ? true : false}  disabled/>
      </Space>
    ),
  },
  {
    title: 'Edit',
    key: 'action',
    width: '5%',
    render: (_, record) => (
      <Space size="middle">
          <EditOutlined onClick={()=> {onEditTerminal(record);}} title="Edit" style={{color:'green'}}></EditOutlined>
       </Space>
    ),
  },
  {
    title: 'Delete',
    key: 'action',
    width: '5%',
    render: (_, record) => (
      <Space size="middle"> 
      <Popconfirm   title="Delete the terminal" description="Are you sure to delete this terminal?"
      onConfirm={() => handleDelete(record)} onCancel={handleCancel} okText="Yes" cancelText="No">
      <DeleteOutlined title="Delete" style={{color:'red'}}/> 
      </Popconfirm>
      </Space>
    ),
  }, 
  
];

const cardstyle = {
  width:'100%',
}

const inputStyle= {
  width:'300px',
}

const divStyle = { 
  width:'100%',
  float: 'left',
}

const divLeft = {
  float: 'left',
  width:'80%',
}

const divRight = {
  float: 'right',
}
const hideTxtBx = {
  display: 'none',
}

const { Search } = Input;

return (   
    <Row gutter={{ xs: 8, sm: 16, md: 24,lg: 32,}}>
      {msgContextHolder} 
      <Card title="" style={cardstyle}>   
        <div style={cardstyle}>
          <div style={divStyle}>
            <div style={divLeft}>         
              <Form form={searchForm} layout="inline" initialValues={{ SearchStatus: -1}} onFieldsChange={(_, allFields) => {  SearchTerminals(allFields); }}>
                <Form.Item label="" name="SearchWord" suffixicon={<SearchIcon />} rules={[{ message: 'Please enter search word' }]}  style={inputStyle} >
                <Search placeholder="Search by Name, Person, Email & Phone" allowClear maxLength={20} />
                </Form.Item>
                <Form.Item label="" name="SearchStatus" >
                  <Radio.Group value={searchStatus} optionType="button" buttonStyle="solid">
                    <Radio value={1}>Active</Radio>
                    <Radio value={0}>Inactive</Radio>
                    <Radio value={-1}>All</Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </div>
            <div style={divRight}>
              <Button type="primary" icon={<PlusSquareOutlined />} onClick={showModal}>Terminal</Button>
            </div>    
          </div>               
        </div>
        <div>&nbsp;</div>  
        <div style={divStyle}>
          <Table rowKey={terminals.key} size="small" columns={columns} dataSource={terminals} pagination={{ pageSizeOptions: ["10", "20","30", "40","50"], pageSize:10, showSizeChanger:false, defaultPageSize: 10 }}/>
        </div>
      </Card>    
      
      <ModalForm open={isModalOpen} onCancel={handleCancelNew} />        
      <Modal  title="Edit Terminal" 
              centered open={isEditing} 
              okText="Save Changes" 
              onOk={handleOk} 
              onCancel={handleCancel}               
              maskClosable={false}
              footer={[
                <Button key="back" onClick={handleCancel}>Cancel</Button>,
                <Button key="submit" type="primary" htmlType="submit" onClick={handleOk}>Save</Button>
              ]}
      >
          <Form form={editForm} layout="horizontal" labelCol={{span: 8}} wrapperCol={{span: 16}} autoComplete="off"
            initialValues={{
              key: editingTerminal?.key,
              TerminalName: editingTerminal?.TerminalName,
              Contact: editingTerminal?.Contact,
              EmailId: editingTerminal?.EmailId,
              Phone: editingTerminal?.Phone,
              Status: editingTerminal?.Status,
            }} 
            onFinish={onSaveTerminal} validateMessages={validateMessages}>
            <Form.Item label="Key" name="key" style={hideTxtBx}><Input disabled={true}  /></Form.Item>
            <Form.Item label="Terminal Name" name="TerminalName" rules={[{ required: true, message: 'Please enter terminal name!' }]} >
            <Input  />
            </Form.Item>
            <Form.Item  label="Address" name="Address" rules={[{ required: true, message: 'Pleaseenter terminal address!' }]}>
            <Input/>
            </Form.Item>
            <Form.Item  label="Contact Person" name="Contact">
            <Input/>
            </Form.Item>
            <Form.Item label="Email" name="EmailId"  help="ex. name@domain.com">
            <Input/>
            </Form.Item>
            <Form.Item label="Phone" name="Phone"  help="ex. +15717894187">
            <Input />
            </Form.Item>    
            <Form.Item label="Status" name="Status" >
              <Radio.Group value={status} optionType="button" buttonStyle="solid">
                <Radio value={1}>Active</Radio>
                <Radio value={0}>Inactive</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>{contextHolder}
      </Modal>     
    </Row>   
  );
};


//New Terminal Form.
// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({ insertForm, open }) => {
  const prevOpenRef = useRef();
  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;
  useEffect(() => {
    if (!open && prevOpen) {
      insertForm.resetFields();
    }
  }, [insertForm, prevOpen, open]);
};

//const [status, setStatus] = useState('1');
const { Option } = Select;
 
const prefixSelector = (
  <Form.Item name="phPrefix" noStyle>
    <Select style={{width: 70,}}>        
      <Option value="+1">+1</Option>
    </Select>
  </Form.Item>
);
const ModalForm = ({ open, onCancel }) => {
  const [insertForm] = Form.useForm();
  const [value, setSValue] = useState(1);
  const [msg, msgContextHolder] = message.useMessage();  

  useEffect(() => {
    ListTerminals('');
  } , [msg]);

  useResetFormOnCloseModal({
    insertForm,
    open,
  });
  const onOk = () => {       
    insertForm.submit();    
  };
  const { user } = useAuth();
  const clientId = user.client;

  const onFinish = (values) => { 
    try
    {
      const isExist = checkExists(values.TerminalName);
      if(isExist ){
        let terminalId = generateId(20);

        let contact = "";
        if (values.Contact !== undefined) {
          contact = values.Contact;
        }
        
        let email =  "";
        if (values.EmailId !== undefined) {
          email = values.EmailId;
        }

        let phPrefix = "";
        if (values.phPrefix !== undefined) {
          phPrefix = values.phPrefix;
        }

        let Phone =  "";
        if (values.Phone !== undefined) {
          Phone = values.Phone;
        }
     
        //Save Terminal  name into the 'lookups' document
        const termDocRef = doc(firestore, clientId, "lookups");
        setDoc(termDocRef, { 'terminalsMap' : {[terminalId] : values.TerminalName}  },{ merge: true });     

        setDoc(doc(firestore, `/${clientId}/details/terminals`,terminalId ), {
          terminalId: terminalId,
          terminalName: values.TerminalName,
          address: values.Address,
          contacts: contact,
          emailId: email,
          //phPrefix: phPrefix,
          phNo: Phone,
          status: values.Status,
        });
        success();
        onCancel();
      }else{        
        let message = "Terminal ("+ values.TerminalName + ") is exist, please enter different terminal details!"
        messageApi.open({type: 'error', content: message});        
      }
    } catch (error) {
      messageApi.open({type: 'error', content: error.message}); 
    }
  };

  const [terminalSearch, setTerminals] = useState([]);
  const ListTerminals = () => {
    try {
      const qry = query(collection(firestore, `/${clientId}/details/terminals`)); 
      onSnapshot(qry, (snapshot) =>  {    
        if(snapshot.docs) {       
            const customerData = [];   
            snapshot.forEach((doc) => {        
               //console.log(doc.id, " => ", doc.data());
              const customer = {};
              customer['key'] = doc.id;              
              const filCustomers = doc.data();
              customer['terminalName'] =   filCustomers.terminalName;          
              customerData.push(customer);           
            });
  
          setTerminals(customerData);
        }       
      });
     } catch (error) {
      messageApi.open({type: 'error', content: error.message}); 
    }
  };
 

function checkExists(termName) {
  let retTerminal = true; 
  try
  {
    let terminalsFilterData = terminalSearch.filter(
      terminal => {
        return (
          typeof terminal.terminalName === "string" && terminal.terminalName.toLowerCase().includes(termName.toLowerCase())
        );
      }
    );  
    let cntTerminal = terminalsFilterData.length; 
    if(cntTerminal > 0)
    {
      retTerminal = false; 
    }  
  } catch (error) {
    messageApi.open({type: 'error', content: error.message}); 
  }    
  return retTerminal; 
}


  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Terminal details has been added successfully.',
    });
  };
  const error = () => {
    messageApi.open({
      type: 'error',
      content: 'This is an error message, Please reveiw the data and save again!',
    });
  };
  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'This is a warning message',
    });
  };

function generateId(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}  

return (   
    <Modal title="New Terminal" 
            open={open} 
            onOk={onOk} 
            onCancel={onCancel}             
            centered 
            maskClosable={false}
            footer={[
              <Button key="back" onClick={onCancel}>Cancel</Button>,
              <Button key="submit"  type="primary" htmlType="submit" onClick={onOk}>Save</Button> 
            ]}
          >
       <Form form={insertForm} name="frmNewTerminal"  onFinish={onFinish} layout="horizontal" labelCol={{span: 8}} wrapperCol={{span: 16}} initialValues={{ Status: 1, }} >
          <Form.Item label="Terminal Name" name="TerminalName" rules={[{ required: true, message: 'Please enter terminal name!' }]} >
          <Input/>
        </Form.Item>
        <Form.Item  label="Address" name="Address" rules={[{ required: true, message: 'Please enter terminal address!' }]}>
          <Input/>
        </Form.Item>
          <Form.Item  label="Contact Person" name="Contact"  >
          <Input/>
        </Form.Item>
          <Form.Item label="Email" name="EmailId"   help="ex. name@domain.com">
          <Input/>
        </Form.Item>
        <Form.Item label="Phone" name="Phone" help="ex. +15717894187">
            <Input />
        </Form.Item>    
        <Form.Item label="Status" name="Status"   >
          <Radio.Group  value={value} optionType="button" buttonStyle="solid">
            <Radio value={1}>Active</Radio>
            <Radio value={0}>Inactive</Radio>
          </Radio.Group>
        </Form.Item>        
        </Form>
        {contextHolder} 
    </Modal>
  );
};
export default TerminalsList; 