import { Formik } from "formik";
import * as Yup from "yup";
import { Alert, Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { fa } from "@faker-js/faker";
import { useEffect, useState,useRef } from "react";

function SignIn() {
    const { signIn } = useAuth();
    const navigate = useNavigate();
    const [isRemember, setRemember] = useState(false);

    const onChange = (e) => {
        setRemember(e.target.checked);
    };

    return (
        <Formik 
            initialValues={
                {
                    email: localStorage.getItem('email'),
                    password: localStorage.getItem('password'),
                    rememberMe: '',
                    submit: false,
                }
            }
            validationSchema={Yup.object().shape({
                email: Yup.string()
                            .email("Must be a valid email")
                            .max(255)
                            .required("Email is required"),
                password: Yup.string()
                            .max(255)
                            .required("Password is required"), 
            })} 
            onSubmit={
                async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        //console.log(values);
                        await signIn(values.email, values.password);
                        if(isRemember){
                            localStorage.setItem('email', values.email);
                            localStorage.setItem('password', values.password);
                        } 
                        //console.log(values);                
                        navigate("/manage");
                    } catch (error) {                        
                        var message = "";
                
                        switch (error.code) {
                            case 'auth/wrong-password':
                                message = "Invalid password.";
                                break;   
                            case 'auth/invalid-email':
                                message = "Invalid email.";
                                break;   
                            case 'auth/user-not-found':
                                message = "Invalid email.";
                                break;                                
                            default:
                                message = error.message || "Something went wrong. Please try again.";
                                break;
                        }

                        setStatus({ success: false });
                        setErrors({ submit: message });
                        setSubmitting(false);
                    }
                }
            }>
            {({errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (        
            <Form onSubmit={handleSubmit}>
                {errors.submit && (
                    <Alert className="my-3" variant="danger">
                        <div className="alert-message">{errors.submit}</div>
                    </Alert>
                )}

                <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control 
                        type="email" 
                        name="email" 
                        placeholder="Enter your email" 
                        size="lg" 
                        value={values.email} 
                        isInvalid={Boolean(touched.email && errors.email)} 
                        onBlur={handleBlur} 
                        onChange={handleChange} />  
                        {!!touched.email && (
                            <Form.Control.Feedback type="invalid">
                                {errors.email}
                            </Form.Control.Feedback>
                        )}                  
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control 
                        type="password" 
                        name="password" 
                        placeholder="Enter your password" 
                        size="lg" 
                        value={values.password}
                        isInvalid={Boolean(touched.password && errors.password)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                    {!!touched.password && (
                        <Form.Control.Feedback type="invalid">
                            {errors.password}
                        </Form.Control.Feedback>
                    )}
                    <small>
                        <Link to="/auth/reset-password">Reset password</Link>
                    </small>
                </Form.Group>

                <div>
                    <Form.Check type="checkbox"  name="rememberMe" onChange={onChange} label="Remember me next time"></Form.Check>
                </div>

                <div className="text-center">
                    <Button type="submit" variant="primary" size="lg" disabled={isSubmitting}>Sign In</Button>
                </div>
            </Form>
            )}
        </Formik>
    );
}

export default SignIn;