import { Card, message, Table } from "antd";
import { collection, onSnapshot, query,doc, getDoc,getDocs,deleteDoc } from "firebase/firestore";
import { useEffect, useState,useRef } from "react";
import { firestore } from "../../firebase/Firebase";
import useAuth from "../../hooks/useAuth";
import { Space,Radio, Button,Form,Input,Tag,Modal,Col,Row,Divider,DatePicker,FloatButton } from 'antd';
import { DownloadOutlined,FileExcelOutlined,FilePdfOutlined, PrinterOutlined,EyeOutlined,DeleteOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import moment from "moment/moment";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'; 
import jsPDF from 'jspdf';  
import html2canvas from 'html2canvas'; 
import html2pdf  from 'html2pdf.js'; 
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;

const WorkordersList = () => {
  const [workorders, setWorkorders] = useState([]);
  const [orders, setOrders] = useState([]);
  const [orderdetails, setOrderDetails] = useState([]);
  const { user } = useAuth();
  const [msg, msgContextHolder] = message.useMessage();
  const [isView, setIsView] = useState(false);
  const clientId = user.client;
  const [searchStatus] = useState('1');
  const [form] = Form.useForm();  
  const [Url, setUrl] = useState('');
  const [workordersRaw, setWorkordersRaw] = useState([]);

  const dateFormat = 'DD-MMM-YYYY'; //'MM/DD/YYYY';
  const dateFormatList = ['MM/DD/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];

  var date=new Date(); 
  let st = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString();
  let et = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString();
 
  
  useEffect(() => {
    ListWorkOrders("");      
  } , [clientId, msg]);

  const ListWorkOrders = (sInput) => {
  try {
    let srhText =sInput.srchText;
    let srhStatus = sInput.srchStatus;
    let srhStDt = sInput.srchStDt;
    let schEnDt = sInput.srchEnDt;

    const qry = query(collection(firestore, `/${clientId}/details/workorders`));  
    const unsubscribe = onSnapshot(qry, (snapshot) => {
      if(snapshot.docs)
      {
        const workorders = [];   
        //console.log('snapshot>',snapshot);
        setWorkordersRaw(snapshot);
        snapshot.forEach((doc) => {     
            const workorder = {};
            workorder['key'] = doc.id;              
            const filWo = doc.data();         
            workorder['BOL'] =   filWo.bol;
            workorder['CustomerName'] = filWo.customer;
            workorder['TerminalName'] = filWo.terminal;
            workorder['DeliveryDate'] =  filWo.delDate[0];
            workorder['DriverName'] = filWo.driver;    
            workorder['SplitCnt'] = filWo.splitcnt;                
            workorder['Status'] =  filWo.woStatus; 
            //workorder['order'] = orders;
            //workorders.push(workorder);     
            //console.log("workorders=>",workorders); 
            const orders = []; 
            const entries = Object.entries(filWo.ordersMap);         
            let deliveryAddress=''; 
            entries.forEach(([key, value]) =>  {               
              const  order = {};   
              //order['DeliveryAddress'] = value.address;        
              deliveryAddress += deliveryAddress+',' + value.address;
              orders.push(order);                                              
            });
            workorder['orders'] = deliveryAddress;
            workorders.push(workorder);     
        });

        let woFilterData=workorders;
        if(srhText !== undefined) {
          woFilterData = workorders.filter(
            woData => {
              return (
                typeof woData.key === "string" && woData.key.toLowerCase().includes(srhText.toLowerCase()) ||
                typeof woData.BOL === "string" && woData.BOL.toLowerCase().includes(srhText.toLowerCase()) ||
                typeof woData.CustomerName === "string" && woData.CustomerName.toLowerCase().includes(srhText.toLowerCase()) ||
                typeof woData.TerminalName === "string" && woData.TerminalName.toLowerCase().includes(srhText.toLowerCase()) ||
                typeof woData.orders === "string" && woData.orders.toLowerCase().includes(srhText.toLowerCase())
              );
            }
          );
        }
       
        if(srhStatus === "All"){
          woFilterData = woFilterData.filter(
            customer => {
              return (
                typeof customer.Status === "string" &&  customer.Status.toLowerCase().includes('completed') ||
                typeof customer.Status === "string" &&  customer.Status.toLowerCase().includes('assigned') ||
                typeof customer.Status === "string" &&  customer.Status.toLowerCase().includes('unassigned')
              );
            }
          );
        } 

        if(srhStatus === "Completed" || srhStatus === "Assigned" || srhStatus==="Unassigned") {
          woFilterData = woFilterData.filter(
            customer => {
              return (
                typeof customer.Status === "string" && (customer.Status.toLowerCase() === srhStatus.toLowerCase())
              );
            }
          ); 
        }

        if(srhStDt !== undefined && schEnDt !== undefined){
          woFilterData = woFilterData.filter(
            customer => {
              return (
                new Date(moment(customer.DeliveryDate, dateFormat).format("LLL")) >= new Date(moment(srhStDt, dateFormat)) &&
                new Date(moment(customer.DeliveryDate,dateFormat).format("LLL")) <=  new Date(moment(schEnDt, dateFormat))
              );
            }
          ); 
        }     
       console.log('woFilterData=>',woFilterData);
        setWorkorders(woFilterData); 
      }
      else
      {
        msg.open({type: 'warning', content: 'No workorder found.'});
      }
    },(error) => {         
      msg.open({type: 'error', content: error.message});        
    },
    );
    return unsubscribe;
  } catch(error) {
    msg.open({
      type: 'error',
      content: error.message,
      duration: 10
    });
  }      
}


const ListOrders = (sInput) => {
  try {
    const qry = query(collection(firestore, `/${clientId}/details/workorders`));  
    const unsubscribe = onSnapshot(qry, (snapshot) => {
      if(snapshot.docs)
      {    
        const orders = [];  
        snapshot.forEach((doc) => {     
          const workorder = {};              
              
            const filWo = doc.data();     
             if(sInput ==  doc.id){
              const entries = Object.entries(filWo.ordersMap);          
              entries.forEach(([key, value]) =>  {               
                const  order = {};        
                const entry = Object.entries(value);           
                order['DelAddress'] = value.address;
                order['Comments'] = value.comments;
                order['DelDate'] = value.delDate;        
                let startDt = typeof value.delStart=== "string" && value.delStart;   
                let endDt = typeof value.delEnd=== "string" && value.delEnd;   
                if(startDt) {
                  order['DelTiming'] =startDt+ " - " + endDt;
                } 
                //order['DelStartDate'] = value.delStart;   
                //order['DelEndDate'] = value.delEnd;   
                order['Status'] = value.status;   
                orders.push(order);                                              
              });
              workorder['order'] = orders;
              workorders.push(workorder);     
            } 
        });
        setOrders(orders);
      }
      else
      {
        msg.open({type: 'warning', content: 'No work order found.'});
      }
    },(error) => {         
      msg.open({type: 'error', content: error.message});        
    },
    );
      return unsubscribe;
    } catch(error) {
      msg.open({
        type: 'error',
        content: error.message,
        duration: 10
      });
    }      
}

const SearchWO = (e) => {    
  let srchText = e[0].value;
  let srchStatus = e[1].value;
  let srchStDt = undefined;
  let srchEnDt = undefined;
 
  if(e[2].value !== null && e[2].value !== undefined){
    srchStDt = e[2].value[0].format(dateFormat);
    srchEnDt = e[2].value[1].format(dateFormat);
  }
  //console.log(srchStDt,srchEnDt );
  const SearchInput = {
    srchText: srchText,
    srchStatus: srchStatus,
    srchStDt: srchStDt,
    srchEnDt: srchEnDt
  }
  ListWorkOrders(SearchInput);    
}

const [isModalOpen, setIsModalOpen] = useState(false);   
const showModal = () => {
  form.resetFields();
  setIsModalOpen(true);
};


const columns = [   
 /* {
    title: 'Workorder Id',
    dataIndex: 'key',  
    width: '10%', 
  },*/
  {
    title: 'Date',
    dataIndex: 'DeliveryDate',   
    key: 'DeliveryDate',    
    width: '20%', 
    //sorter: (a, b) =>  new Date(moment(a.date,dateFormat)) - new Date(moment(b.date,dateFormat)), 
  }, 
  {
    title: 'BOL #',
    dataIndex: 'BOL',     
    width: '10%',  
  },
  {
    title: 'Customer',
    dataIndex: 'CustomerName',
    sorter: (a, b) => a.CustomerName.localeCompare(b.CustomerName),
  },
  {
    title: 'Terminal',
    dataIndex: 'TerminalName',  
    sorter: (a, b) => a.TerminalName.localeCompare(b.TerminalName),
  },
  {
    title: 'Driver',
    dataIndex: 'DriverName',  
    //sorter: (a, b) => a.DriverName.localeCompare(b.DriverName),
  },
  {
    title: "Orders",
    dataIndex:'SplitCnt',
    key:'SplitCnt',
  },
  {
    title: 'Status',
    dataIndex: 'Status',  
    render: (record) => (  
      <span>         
      <Space size="middle">    
        <Tag color={record === "Completed" ? "success" : (record === "Unassigned" ? "error" : "processing")}  key={record}>
          {record}
        </Tag>
      </Space>
      </span>
    ),
  }, 
  {
    title: '',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
      <EyeOutlined onClick={()=> {onViewWorkorder(record);}} title='View Work Order' style={{color:'blue'}}/>
      </Space>
    ),
  }, 
];
const expandedRowRender = () => {
  const columns = [   
    {
      title: 'Address',
      dataIndex: 'DelAddress',  
      
    },
    /*{
      title: 'Comments',
      dataIndex: 'Comments',      
    },*/
    {
      title: 'Delivery Date',
      dataIndex: 'DelDate',       
    },
    {
      title: 'Time Frame',
      dataIndex: 'DelTiming',      
    },
     
    {
      title: 'Status',
      key: 'Status',    
      dataIndex: 'Status',     
      render: (record) => (  
        <span>         
        <Space size="middle">    
          <Tag color={record === "Completed" ? "success" : (record === "open" ? "error" : "processing")}  key={record}>
            {record}
          </Tag>
        </Space>
        </span>
      ),        
    },    
     
  ];
 /* const data =  ListWorkOrders("order");    
  for (let i = 0; i < 3; ++i) {
    data.push({
      key: i.toString(),
      date: '2014-12-24 23:12:00',
      name: 'This is production name', 
      upgradeNum: 'Upgraded: 56',
    });
  }*/
  return <Table rowKey={columns.key} columns={columns} dataSource={orders} pagination={false} size="small" bordered/>;
};

const cardstyle = {
  width:'100%',
}
  
  const inputStyle= {
    width:'250px',
  }
  
  
  const divStyle = { 
    width:'100%',
    float: 'left',
  }
  
  const divLeft = {
    float: 'left',
    width:'98%',
  }
  
  
  const divRight = {
    float: 'right',
    margin: '0px'
  
  }

  const divHide= {
    display: 'none',
  }
  

  const [expandedKey, setExpandedKey] = useState(null);

  const onExpandTable = (_, { key }) => {
    expandedKey === key ? setExpandedKey(null) : setExpandedKey(key);
    //console.log("test=>", key);
    ListOrders(key);
  } 

  const handleCancelNew = () => {
    setIsModalOpen(false);
  };

  const { Search } = Input; 

  const handleOk = (e) => {
    //console.log(e);
    setIsView(false);
  };
  
  const handleCancel = (e) => {
    //console.log(e); 
    setIsView(false);
  };  

  const onViewWorkorder =  (record) => {    
    setIsView(true);   
    GetWorkOrderDetail(record.key);    
  };

  const GetWorkOrderDetail = (sInput) => {
    try {           
      getDoc(doc(firestore, `/${clientId}/details/workorders`, sInput)).then(docSnap => {
        if (docSnap.exists()) {
          //console.log("Document data:", docSnap.data());          
          //setOrderDetails(docSnap.data()); 

          const data = docSnap.data();
          let woDetails = [];
          //console.log("Document data:",data);  
          woDetails['Key'] = sInput;

          let woBasicDetails = [];
          woBasicDetails['BOL'] = data.bol;
          woBasicDetails['DeliveryDate'] = data.delDate;
          woBasicDetails['Status'] = data.woStatus;
         //woDetails.push(woBasicDetails);
          woDetails['BasicDetails'] = woBasicDetails;

          let woDriverDetails = [];
          woDriverDetails['DriverId'] = data.driverid;
          woDriverDetails['DriverName'] = data.driver;
          //woDetails.push(woDriverDetails);
          woDetails['DriverDetails'] = woDriverDetails;

          let woCompanyDetails = [];
          woCompanyDetails['CompanyId'] = data.customerid;
          woCompanyDetails['CompanyName'] = data.customer;
          //woDetails.push(woCompanyDetails);
          woDetails['CompanyDetails'] = woCompanyDetails;

          let woTerminalDetails = [];
          woTerminalDetails['TerminalId'] = data.terminalid;
          woTerminalDetails['TerminalName'] = data.terminal;
          woTerminalDetails['TerminalAddress'] = data.terminalAddress;
          //woDetails.push(woTerminalDetails);
          woDetails['TerminalDetails'] = woTerminalDetails;
          
          let woOrderDetails = [];
          const entries = Object.entries(docSnap.data().ordersMap);
         
          entries.forEach(([key, value]) =>  {
            const order =[];
            let woId =`${key}`             
            const entry = Object.entries(value);           
            
            order['key'] = woId;  
            order['DelAddress'] = value.address;
            order['Comments'] = value.comments;
            order['DelDate'] = value.delDate;        
            let startDt = typeof value.delStart=== "string" && value.delStart;   
            let endDt = typeof value.delEnd=== "string" && value.delEnd;   
            if(startDt) {
              order['DelTiming'] = startDt + " - " + endDt;
            }                 
            order['Status'] = value.status; 

            //Fuel Details
            let fuels = [];
            //console.log("value.orddisplay",value.orddisplay);
            //console.log("value.orddisplay.DA",value.orddisplay.DA);
            const fuel = [];      
            fuel['fuelKey'] =woId; //`${key}`;
            fuel['DA'] = value.orddisplay.DA; 
            fuel['HA'] = value.orddisplay.HA;
            fuel['PA'] = value.orddisplay.PA;
            fuel['RA'] = value.orddisplay.RA;
            fuel['DO'] = value.orddisplay.DO;  
            fuel['HO'] = value.orddisplay.HO;
            fuel['PO'] = value.orddisplay.PO;
            fuel['RO'] = value.orddisplay.RO;
            fuels.push(fuel);         
            /*const fuelData = Object.entries(value.orddisplay);
              fuelData.forEach(([key, value]) =>  {              
            });*/   
            order['Fuel'] = fuels; 
            //order.push(fuels);

            //Attachment Links
            let docLinks = [];
            const docurl = Object.entries(value.docurls);
            docurl.forEach(([key, value]) =>  {             
              const docLink = [];            
                docLink['docKey'] = woId;//`${key}`;  
                docLink['docLink'] = value[0];
                docLink['docDate'] = value[1];
                docLink['docId'] = value[2];
                docLinks.push(docLink);             
            });      
            order['Docs'] = docLinks;     
            //order.push(docLinks);
             woOrderDetails.push(order);
            //woOrderDetails['orderD']= order;                      
          });
          woDetails['Orders'] = woOrderDetails;
          //woDetails.push(woOrderDetails);
          //console.log("woDetails=>",woDetails);
          setOrderDetails(woDetails); 
        }
      });       
    } catch(error) {
      msg.open({ type: 'error',  content: error.message,  duration: 10 });
    }      
  }

 

const exportExcel= (e) => {
  //console.log("exportExcel=>",chekedRows);    
  GetWorkOrderForExcel(chekedRows);
}

const exportPDF= (e) => {
  GetWorkOrderForPdf(chekedRows);
}

const tableRef = useRef(null);
const GetWorkOrderForExcel = (sInput) => {
  try {      
    if(sInput === false){
      msg.open({type: 'warning', content: 'Please select the work order(s) to export excel!'});
    }
    else
    {  
      let receiptData =[];
      console.log('workordersRaw=>',workordersRaw);
      const exlWorkorders = [];   
    
      workordersRaw.forEach((doc) => {     
          const workorder =[];
          //workorder['key'] = doc.id;              
          const filWo = doc.data();         
          
          //workorder['CustomerName'] = filWo.customer;
          //workorder['DriverName'] = filWo.driver;
          //workorder['Status'] =  filWo.woStatus; 
        
          //Orders
          const orders = []; 
          const entries = Object.entries(filWo.ordersMap);         
          let deliveryAddress=''; 
          let orderCnt = 0;
          entries.forEach(([key, value]) =>  {  
            //orderCnt = filWo.splitcnt;
            if(orderCnt == 0){
              workorder['Date'] =  filWo.delDate[0];
              workorder['Order(s)'] = filWo.splitcnt;           
              workorder['BOL No.'] =   filWo.bol;
           
              const trmnls = Object.entries(filWo.terminal);  
              let trmnlName ='';     
              trmnls.forEach(([key, value]) =>  {    
                trmnlName += value + ',';   
              });
              workorder['Supplier Terminal'] = trmnlName; 
            }/*else{
              workorder['Date'] = '';
              workorder['Order(s)'] = '';        
              workorder['BOL No.'] =  '';
              workorder['Supplier Terminal'] = '';
            }*/

            workorder['Drop Destination'] = value.address;      
            //Ordered
            workorder['Regular'] = value.orddisplay.RO;            
            workorder['Super'] = value.orddisplay.SO; 
            workorder['Disel'] = value.orddisplay.DO; 
            workorder['Heating Oil'] = value.orddisplay.HO;
            workorder['Plus'] = value.orddisplay.PO;
            let totalOrdered = (value.orddisplay.RO + value.orddisplay.SO+ value.orddisplay.DO + value.orddisplay.HO + value.orddisplay.PO);
            workorder['Total'] = totalOrdered;

            //Actual
            workorder['Regular Actual'] = value.orddisplay.RA;            
            workorder['Super Actual'] = value.orddisplay.SA; 
            workorder['Disel Actual'] = value.orddisplay.DA; 
            workorder['Heating Oil Actual'] = value.orddisplay.HA;
            workorder['Plus Actual'] = value.orddisplay.PA;
            let totalActual = (value.orddisplay.RA + value.orddisplay.SA + value.orddisplay.DA + value.orddisplay.HA + value.orddisplay.PA);
            workorder['Total Actual'] = totalActual;
          
           
            //deliveryAddress += value.address + ',';
            //orders.push(order);        
            exlWorkorders.push(workorder);       
            orderCnt++;                                    
          });
          //workorder['orders'] = deliveryAddress;
          
      });

      console.log('exlWorkorders=>',exlWorkorders);

      var date=new Date(); 
      let exlFileName = "Workorder_"+ (date.getMonth()+1) +"_"+date.getDate()+"_"+date.getFullYear()+"_"+date.getHours()+"_"+date.getMinutes() +"_"+date.getSeconds() +".xlsx";
      const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const ws = XLSX.utils.json_to_sheet(exlWorkorders);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data1 = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data1, exlFileName);    
    }
  } catch(error) {
    msg.open({ type: 'error',  content: error.message,  duration: 10 });
  }      
}

const GetWorkOrderForPdf = (sInput) => {
  try {      
    if(sInput === false){
      msg.open({type: 'warning', content: 'Please select the work order(s) to download PDF!'});    
    }
    else
    {        
      const q = query(collection(firestore, `/${clientId}/details/workorders`));
      const querySnapshot = getDocs(q).then(
      function(snapshot) {
        if (snapshot.docs.length > 0) {
          let woDataPdf=[];
            snapshot.docs.forEach(doc => {
              sInput.split(',').map((woId) => {
                const data = doc.data();
                if(doc.id===woId){ 
                  //woDataPdf.push(doc.data());
                  const entries = Object.entries(doc.data().ordersMap);                    
                  entries.forEach(([key, value]) =>  {                       
                    const docurl = Object.entries(value.docurls);
                    docurl.forEach(([key, value]) =>  {             
                      const docLink = [];            
                        //docLink['docKey'] = woId;//`${key}`;  
                        docLink['docLink'] = value[0];
                        docLink['docDate'] = value[1];                          
                        // docLink['docId'] = value[2];
                        woDataPdf.push(docLink);             
                    });                
                  });
                }
              });
            })  
            generatePDF(woDataPdf);                       
        }
        else {
          msg.open({type: 'warning', content: 'There is no work order to download as PDF!'});   
        }
      });
    }
  } catch(error) {
    msg.open({ type: 'error',  content: error.message,  duration: 10 });
  }      
}
const generatePDF = (pdfData) =>{
  let totalRows = pdfData.length;
  let cnt = 0;
  if(totalRows === 0){
    msg.open({type: 'error', content: 'Selected work orders are not having any scanned bills/receipts!'});   
  }
  else
  {
    var docPdf = new jsPDF();
    docPdf.setFontSize(20);
    docPdf.text("Work Orders", 35, 25);
    pdfData.map(data => { 
        console.log(data);
        docPdf.addImage(encodeURIComponent(data.docLink), "JPEG", 15, 40, 180, 180);
        cnt++;
        if(cnt < totalRows){
          docPdf.addPage("a4", "p");
        }    
    })         
    var date=new Date(); 
    let fileName = "WorkOrder_"+ (date.getMonth()+1) +"_"+date.getDate()+"_"+date.getFullYear()+"_"+date.getHours()+"_"+date.getMinutes() +"_"+date.getSeconds() +".pdf";
    docPdf.save(fileName); 
  }
}
const { confirm } = Modal;
const showDeleteConfirm = () => {
  if(chekedRows === false){
    msg.open({type: 'warning', content: 'Please select the work order(s) to delete!'});    
  }
  else
  { 
    confirm({
      title: 'Delete Confirmation',
      icon: <ExclamationCircleOutlined  />,
      content: 'Are you sure want to delete the selected work order(s)?',
      okText: 'Yes',    
      cancelText: 'No',   
      onOk() {
        console.log("deleteWOs=>",chekedRows);   
        DeleteWorkOrders(chekedRows); 
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
};


const DeleteWorkOrders = (sInput) => {
  try {      
    if(sInput === false){
      msg.open({type: 'warning', content: 'Please select the work order(s) to delete!'});    
    }
    else
    { 
      sInput.split(',').map((woId) => {
        deleteDoc(doc(firestore, `/${clientId}/details/workorders`, woId));
      });
      msg.open({ type: 'success',  content:'Work Order(s) has beed deleted successfully!',  duration: 10 });
    }
  } catch(error) {
    msg.open({ type: 'error',  content: error.message,  duration: 10 });
  }      
}

const onWoPrint = (e) =>{
 // msg.open({type: 'success', content: 'Print Page!'});   
  var date=new Date(); 
  let fileName = "WorkOrder_"+ (date.getMonth()+1) +"_"+date.getDate()+"_"+date.getFullYear()+"_"+date.getHours()+"_"+date.getMinutes() +"_"+date.getSeconds() +".pdf";
  
  var element = document.getElementById('pdfdiv');
  var opt = {
    margin:       0.3,
    filename:     fileName,
    image:        { type: 'jpeg', quality: 0.98 },
    html2canvas:  { scale: 2 },
    jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
  };
  html2pdf().from(element).set(opt).save();

  //let htmlString = "<!DOCTYPE html><html><body><p><b>This text is bold</b></p><p><i>This text is italic</i></p><p>This is<sub> subscript</sub> and <sup>superscript</sup></p><div><span>I'm on page 1!<img width='90' height='90' src='https://firebasestorage.googleapis.com/v0/b/fleet-poc-9e021.appspot.com/o/GSH%2Fworkorders%2FqUYk9mBle3HIxKNSCaD0%2F20235162139019%2F2023516222959.jpg?alt=media&token=4ff4e8ac-d506-47a3-8122-c8c37ffe483e'></img></span> <div class='html2pdf__page-break'></div> <span>I'm on page 2!</span></div></body></html>";
  //let pdfBlobOutput = htmlStringToPdf(htmlString);
}

  const htmlStringToPdf = async (htmlString) => {
    let iframe = document.createElement("iframe");
    iframe.style.visibility = "hidden";
    document.body.appendChild(iframe);
    let iframedoc = iframe.contentDocument || iframe.contentWindow.document;
    iframedoc.body.innerHTML = htmlString;
    
    let canvas = await html2canvas(iframedoc.body, {});
    
    // Convert the iframe into a PNG image using canvas.
    let imgData = canvas.toDataURL("image/png");
  
    // Create a PDF document and add the image as a page.
    const doc = new jsPDF({
      format: "a4",
      unit: "mm",
    });
    doc.addImage(imgData, "PNG", 0, 0, 210, 297);
  
    doc.save("download.pdf");

    // Get the file as blob output.
    let blob = doc.output("blob");
  
    // Remove the iframe from the document when the file is generated.
    document.body.removeChild(iframe);
  };

  const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const onFileDownload = (imgUrl)=> { 
    //const storageRef =storage.bucket(); //ref(storage, imgUrl).bucket();
    //const file = storageRef.file("uploads/file.txt");     
    //console.log(file);    
   /* const input = document.getElementById('divToPrint'); 
    console.log(input);  
    html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'pt','a4',true);
        pdf.addImage(imgData, 'JPEG', 0, 0);
        // pdf.output('dataurlnewwindow');
        pdf.save("download.pdf");
    });
*/

 
// New Promise-based usage:

 
/*
    var element = document.getElementById('divToPrint').innerHTML;
    var opt = {
    margin:       10,
    filename:     'test.pdf',
    image:        { type: 'jpeg', quality: 1 },
    html2canvas:  { dpi: 300, letterRendering: true, width: 1080, height: 1920},
    jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait' },
    pagebreak:    { mode: ['avoid-all', 'css'] }
    };
    // Choose the element and save the PDF for our user.
    html2pdf().from(element).set(opt).save();*/
  }

  const [chekedRows, setchekedRows] = useState(false);
  const [checkStrictly, setCheckStrictly] = useState(false);
  // rowSelection objects indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setchekedRows(`${selectedRowKeys}`); 
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    },
  };
  
  const style = {
    background: '#0092ff',
    padding: '8px 0',
  };

  const lableStyle={
    background: '#4096ff42',
    padding: '5px',    
    fontWeight: '500',
  }

  const dataStyle={
    background: '#eff2f6',
    padding: '5px',
  }

  return (  
    <Card title="">      
    
      <div style={cardstyle}>
          <div style={divStyle}>
              <div style={divLeft}>
              <Space>
                <Form form={form} name="frmSearch"  layout="inline" initialValues={{ SearchStatus: "All"}} 
                onFieldsChange={(_, allFields) => {  SearchWO(allFields); }}>
                  <Form.Item label="" name="SearchWord" style={inputStyle} >
                  < Search placeholder="Search by Work Order/BOL/Company/Terminal" allowClear maxLength={20} title="Search by Work Order/BOL/Company/Terminal"/>
                  </Form.Item>
                  <Form.Item label="" name="SearchStatus" >
                    <Radio.Group  value={searchStatus} optionType="button" buttonStyle="solid" style={{ width: '100%' }}>
                      <Radio value={'Completed'}>Completed</Radio>
                      <Radio value={'Assigned'}>Assigned</Radio>
                      <Radio value={'Unassigned'}>Unassigned</Radio>
                      <Radio value={'All'}>All</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item label="" name="SearchDeliveryDate" >     
                    <RangePicker format={dateFormat} style={{ width: '75%' }}/>
                  </Form.Item>
                </Form>
                </Space>
              </div>              
          </div>
          <div>&nbsp;</div> 
          <div>
            <div></div>
            <div style={divRight}>    
              <Space>          
                <Button type="primary" icon={<FileExcelOutlined />} onClick={exportExcel} title="Export Excel">Export Excel</Button>
                <Button type="primary" icon={ <FilePdfOutlined />} onClick={exportPDF} title="Download PDF">Download PDF</Button>     
                <Button type="primary" icon={ <DeleteOutlined />}  title="Delete" onClick={showDeleteConfirm} danger>Delete</Button>     
              </Space>           
              </div>
          </div>
          <div>&nbsp;</div> 
        </div>
        <div>&nbsp;</div>  
        <div style={divStyle}>
        <Table columns={columns}  dataSource={workorders}   
          rowSelection={{
            ...rowSelection,
          }}    
          expandable={{  expandedRowRender, 
            defaultExpandedRowKeys: ['0'], onExpand : onExpandTable,expandedRowKeys: [expandedKey] }}   
        />
        </div>
        <FloatButton.BackTop />
        <ModalForm open={isModalOpen} onCancel={handleCancelNew} /> 
        <Modal  title="Work Order Details" centered open={isView} okText="Save Changes" onOk={handleOk} onCancel={handleCancel} footer={[]}  maskClosable={false} width={800}>
        <Row>
          <Col span={17}></Col>
          <Col span={7}>
            <Button key="back" onClick={handleCancel}>Cancel</Button>&nbsp;&nbsp;&nbsp;
            <Button type="primary"  icon={<PrinterOutlined />} onClick={onWoPrint}>Export PDF</Button>
          </Col>
        </Row>
        <div id='pdfdiv'>
        <Divider orientation="left">Basic Details</Divider>   
        <Row>         
          <Col span={6} style={lableStyle}>BOL:</Col>
          <Col span={6} style={dataStyle}>{orderdetails?.BasicDetails?.BOL}</Col>
          <Col span={6} style={lableStyle}>Status</Col>
          <Col span={6} style={dataStyle}>            
            <span>         
            <Space size="middle">    
              <Tag color={orderdetails?.BasicDetails?.Status === "Completed" ? "success" : (orderdetails?.BasicDetails?.Status === "Unassigned" ? "error" : "processing")}  key={orderdetails?.BasicDetails?.Status}>
                {orderdetails?.BasicDetails?.Status}
              </Tag>
            </Space>
            </span>
          </Col>
        </Row> 
        <Row>
          <Col span={6} style={lableStyle}>Customer Name</Col>
          <Col span={6} style={dataStyle}>{orderdetails?.CompanyDetails?.CompanyName}</Col>   
          <Col span={6} style={lableStyle}>Delivery Date</Col>
          <Col span={6} style={dataStyle}>{orderdetails?.BasicDetails?.DeliveryDate}</Col>          
        </Row>   
        <Row>
          <Col span={6} style={lableStyle}>Address</Col>
          <Col span={6} style={dataStyle}>{orderdetails?.TerminalDetails?.TerminalAddress}</Col>
          <Col span={6} style={lableStyle}>Driver Name</Col>
          <Col span={6} style={dataStyle}>{orderdetails?.BasicDetails?.Status === "Unassigned" ? ( <Tag color="error">Unassigned</Tag>) : (orderdetails?.DriverDetails?.DriverName) } </Col>        
        </Row> 
        <Row>
          <Col span={6} style={lableStyle}>Terminal Name</Col>
          <Col span={6} style={dataStyle}>{orderdetails?.TerminalDetails?.TerminalName} </Col>
          <Col span={6} style={lableStyle}>Work Order Id:</Col>
          <Col span={6} style={dataStyle}>{orderdetails.Key}</Col>
        </Row>   
              
        <Row>
               
        </Row>
        
        <Divider orientation="left">Order Details</Divider>  
        {orderdetails?.Orders?.map((ord) => { 
          return ( 
            <div style={{border:'1px solid #4096ffbf', marginBottom: '3px'}} className="html2pdf__page-break">
            <Row >
              <Col span={3} style={lableStyle}>Address</Col>
              <Col span={3} style={dataStyle}>{ord?.DelAddress}</Col>         
              <Col span={3} style={lableStyle}>Date</Col>
              <Col span={3} style={dataStyle}>{ord?.DelDate }</Col>
              <Col span={3} style={lableStyle}>Time Frame</Col>
              <Col span={3} style={dataStyle}>{ord?.DelTiming}</Col>
              <Col span={3} style={lableStyle}>Status</Col>
              <Col span={3} style={dataStyle}>
              <span>         
                <Space size="middle">    
                  <Tag color={ord?.Status === "Completed" ? "success" :  "error"}  key={ord?.Status}>
                    {ord?.Status}
                  </Tag>
                </Space>
                </span>
              </Col>
              </Row>   
              <Row>
              <Col span={21} >  
              {ord.Fuel?.map((order) => { 
                return ( 
                  <div width='100%'>
                     <table cellPadding={5} cellSpacing={5} style={{width:'100%'}}>
                      <tr>
                        <td style={{ verticalAlign:'top',textAlign:'-webkit-center'}}> 
                        <table cellPadding={5} cellSpacing={5} style={{border:'1px solid lightgray', marginBottom: '2px', width:'50%'}}>
                          <tr>
                            <td style={lableStyle}>Products</td>
                            <td style={lableStyle}>Order Quantity</td>
                            <td style={lableStyle}>Actual Quantity</td>
                          </tr>
                          <tr>
                            <td style={lableStyle}>Regular</td>
                            <td>{order?.RO}</td>
                            <td>{order?.RA}</td>
                          </tr>
                          <tr>
                            <td style={lableStyle}>Plus</td>
                            <td>{order?.PO}</td>
                            <td>{order?.PA}</td>
                          </tr>
                          <tr>
                            <td style={lableStyle}>Super</td>
                            <td>{order?.SO}</td>
                            <td>{order?.SA}</td>
                          </tr>
                          <tr>
                            <td style={lableStyle}>Disel</td>
                            <td>{order?.DO}</td>
                            <td>{order?.DA}</td>
                          </tr>
                          <tr>
                            <td style={lableStyle}>Heating Oil</td>
                            <td>{order?.HO}</td>
                            <td>{order?.HA}</td>
                          </tr>
                        </table>
                        </td>                        
                        </tr>
                    </table>                                            
                  </div>
                )}     
              )} 

              </Col>
            </Row> 
            { 
              ord?.Docs.length === 0 ? (
                ""
              ) : (
                <Row >
                <Col span={3} style={lableStyle}>Attachments</Col>  
                <Col span={21} style={dataStyle}>  
                <table cellPadding={5} cellSpacing={5} style={{ width:'20%'}}>                  
                  <tr>
                {ord.Docs?.map((img) => { 
                  return ( 
                    <td >
                      <table cellPadding={0} cellSpacing={0} style={{ width:'90px'}}>
                        <tr><td colSpan={3}><a href={img?.docLink} target="_new"> <img width={90} height={90} src={img?.docLink}></img> </a></td></tr>
                        <tr style={{background:'#ffffff'}}>
                          <td><a href={img?.docLink} target="_new"><Button type="link" icon={<EyeOutlined />} block title="View"></Button></a></td>
                          <td></td>
                          <td><Button type="link" icon={<DownloadOutlined />} block title="Download" onClick={onFileDownload(img?.docLink)}></Button></td>
                        </tr>
                      </table>   
                    </td>                          
                  )}     
                )}  
                 </tr>
                 
                    </table>  
                    
                 </Col>     
              </Row>
               )
              }
             
            { 
              ord?.Comments === "" ? (
                ""
              ) : (
                 <Row>
                  <Col span={3} style={lableStyle}>Comments</Col>
                  <Col span={21} style={dataStyle}>{ord?.Comments}</Col>   
               </Row>
              )
            }
            
             </div>
         )}     
        )}           
        </div>
        <Row>
          <Col span={17}></Col>
          <Col span={7}>
            <Button key="back" onClick={handleCancel}>Cancel</Button>&nbsp;&nbsp;&nbsp;
            <Button type="primary"  icon={<PrinterOutlined />} onClick={onWoPrint}>Export PDF</Button>
          </Col>
        </Row>     
        </Modal>       
      {msgContextHolder}        
    </Card>
  );
};

const ModalForm = ({ open, onCancel }) => {
  const onOk = () => {   
  };
  
return (   
  <Modal title="New Work Order" open={open} onOk={onOk} onCancel={onCancel} footer={[]} centered >
    New Work Order screen comes here...  
  </Modal>
);
};

export default WorkordersList; 