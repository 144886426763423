// export const firebaseConfig = {
//     apiKey: process.env.FIREBASE_APIKEY,
//     authDomain: process.env.FIREBASE_AUTHDOMAIN,
//     projectId: process.env.FIREBASE_PROJECTID,
//     storageBucket: process.env.FIREBASE_STORAGEBUCKET,
//     messagingSenderId: process.env.FIREBASE_MESSAGINGSENDERID,
//     appId: process.env.FIREBASE_APPID
//   };

  // export const firebaseConfig = {
  //   apiKey: "AIzaSyC2iVSbQwqlQ4GwQEADUiwwxb3BK2EH5Ok",
  //   authDomain: "fleet-poc-9e021.firebaseapp.com",
  //   projectId: "fleet-poc-9e021",
  //   storageBucket: "fleet-poc-9e021.appspot.com",
  //   messagingSenderId: "16811616345",
  //   appId: "1:16811616345:web:b9a59912dec886808de948"
  // };

  export const firebaseConfig = {
    apiKey: "AIzaSyBJwFpHKVsIoIrI76JcwLmJCmtAELOD02Q",
    authDomain: "talentmindsllc-424e7.firebaseapp.com",
    projectId: "talentmindsllc-424e7",
    storageBucket: "talentmindsllc-424e7.appspot.com",
    messagingSenderId: "569435905430",
    appId: "1:569435905430:web:d634642cda35691f4411b7",
    measurementId: "G-2336WK3W5M"
  };