import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Support from "../../components/public/support";

const SupportViewPage = () => (
    <React.Fragment>
        <Helmet title="Support" />
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Support</h1>
            <Support />
        </Container>       
    </React.Fragment>
);

export default SupportViewPage;