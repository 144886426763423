import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { store } from "./redux/store";

import { ThemeProvider } from "./contexts/ThemeContext";
import { AuthProvider } from "./contexts/FirebaseAuthContext";

import "./i18n";
import routes from "./routes";
import { SidebarProvider } from "./contexts/SidebarContext";
import { LayoutProvider } from "./contexts/LayoutContext";

const App = () => {

  const content = useRoutes(routes);

  return (
    <HelmetProvider>
      <Helmet defaultTitle="Fleet Suite Management App" 
        titleTemplate="%s | Fleet Suite Management App" />        
        <Provider store={store}>
          <ThemeProvider>
            <SidebarProvider>
              <LayoutProvider>
                <AuthProvider>{content}</AuthProvider>
              </LayoutProvider>
            </SidebarProvider>
          </ThemeProvider>
        </Provider>
    </HelmetProvider>
  );
}

export default App;
