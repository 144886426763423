import ScrollBar from "react-perfect-scrollbar";
import useSidebar from "../../hooks/useSidebar";
import logo from "../../assets/img/logo.png"
import SidebarNav from "./SidebarNav";


const Sidebar = ({ items, showFooter = true }) => {
    const { isOpen } = useSidebar();

    return (
        <nav className={`sidebar ${!isOpen ? "collapsed" : ""}`}>
            <div className="sidebar-content">
                <ScrollBar>
                    <a className="sidebar-brand" href="/manage">
                        <img className="App-logo" alt="Talent Minds" width="30" height="20" src={logo} /> 
                        <span className="align-middle me-3">Fleet Suite</span>
                    </a>
                    <SidebarNav items={items} />
                </ScrollBar>
            </div>
        </nav>
    );
};

export default Sidebar;