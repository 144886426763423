import { Formik } from "formik";
import * as Yup from "yup";
import { Alert, Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

function ResetPassword() {
    const { resetPassword } = useAuth();
    const navigate = useNavigate();

    return (
        <Formik 
            initialValues={
                {
                    email: "",
                    password: "",
                    submit: false,
                    success: false,
                }
            }
            validationSchema={Yup.object().shape({
                email: Yup.string()
                            .email("Must be a valid email")
                            .max(255)
                            .required("Email is required")                
            })} 
            onSubmit={
                async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        //console.log(values);
                        await resetPassword(values.email);  

                        var message = "System has been sent the 'Reset Password' mail to your email address. Please check it and reset the password!";
                        values.success = true;
                        setErrors({ submit: message });
                        //console.log(values);                
                        //navigate("/manage");
                    } catch (error) {                        
                        var message = "";
                        values.success = false;
                        switch (error.code) {
                            case 'auth/wrong-password':
                                message = "Invalid password.";
                                break;   
                            case 'auth/invalid-email':
                                message = "Invalid email.";
                                break;   
                            case 'auth/user-not-found':
                                message = "Invalid email.";
                                break;                                
                            default:
                                message = error.message || "Something went wrong. Please try again.";
                                break;
                        }

                        setStatus({ success: false });
                        setErrors({ submit: message });
                        setSubmitting(false);
                    }
                }
            }>
            {({errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (        
            <Form onSubmit={handleSubmit}>
                {errors.submit && (
                    <Alert className="my-3" variant={values.success === true ? "success" : "danger"}>
                        <div className="alert-message">{errors.submit}</div>
                    </Alert>                   
                )}
                
                <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control 
                        type="email" 
                        name="email" 
                        placeholder="Enter your email" 
                        size="lg" 
                        value={values.email} 
                        isInvalid={Boolean(touched.email && errors.email)} 
                        onBlur={handleBlur} 
                        onChange={handleChange} />  
                        {!!touched.email && (
                            <Form.Control.Feedback type="invalid">
                                {errors.email}
                            </Form.Control.Feedback>
                        )}                  
                </Form.Group>

                <Form.Group className="mb-3">                   
                    <small>
                        <Link to="/">Back to Sign In?</Link>
                    </small>
                </Form.Group>

                <div className="text-center">
                    <Button type="submit" variant="primary" size="lg" disabled={isSubmitting}>Reset Password</Button>
                </div>
            </Form>
            )}
        </Formik>
    );
}

export default ResetPassword;