import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Header from "../../components/dashboard/Header";
import WorkorderStatistcs from "../../components/dashboard/WorkorderStatistcs";
import WorkorderChart from "../../components/dashboard/WorkorderChart";

const DashboardPage = () => (
    <React.Fragment>
        <Helmet title="Dashboard" />
        <Container fluid className="p-0">
          
            <WorkorderStatistcs />
            <WorkorderChart />
        </Container>
    </React.Fragment>
);

export default DashboardPage;