import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import TerminalsList from "../../components/terminals/TerminalsList";

const TerminalsPage = () => (
    <React.Fragment>
        <Helmet title="Terminals" />
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Terminals</h1>
            <TerminalsList />
        </Container>       
    </React.Fragment>
);

export default TerminalsPage;