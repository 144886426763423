import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import WorkorderView from "../../components/workorders/WorkorderView";

const WorkorderViewPage = () => (
    <React.Fragment>
        <Helmet title="Workorders" />
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Workorders</h1>
            <WorkorderView />
        </Container>       
    </React.Fragment>
);

export default WorkorderViewPage;