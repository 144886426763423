import React from "react";
import { Outlet } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import ResetPassword from "../../components/auth/reset-password";
import Wrapper from "../../components/Wrapper";
import Footer from "../../components/Footer";
import Sidebar from "../../components/sidebar/Sidebar";
import { Container } from "react-bootstrap";
import Navbar from "../../components/navbar/Navbar";
import Content from "../../components/Content";
import navItems from "../../components/sidebar/SidebarMenuItems"
import Main from "../../components/Main"

const leadTitle = { 
  color: 'white',
}
const heightS = { 
 height:'100%',
 display:'flex',
 flexFlow: 'column',
 flex: '1 1 auto'
}

const SignInPage = () => (
    <React.Fragment>                    
      <div className="text-center mt-4">           
          <p style={leadTitle} className="lead ">Reset Password on your account to continue</p>
      </div>
      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <div className="text-center">
              {/* <img
                src={avatar}
                alt="Chris Wood"
                className="img-fluid rounded-circle"
                width="132"
                height="132"
              /> */}
            </div>
            <ResetPassword />
          </div>
        </Card.Body>
      </Card>       
    </React.Fragment>
);

export default SignInPage;