import { Card, message, Table } from "antd";
import { collection, onSnapshot, query,doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { firestore } from "../../firebase/Firebase";
import useAuth from "../../hooks/useAuth";
import { Badge, Dropdown, Space,Radio, Button,Form,Input,Tag,Modal,Col,Row,Divider,DatePicker,FloatButton } from 'antd';
import { DownloadOutlined,ExportOutlined } from '@ant-design/icons';
import moment from "moment/moment";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { string } from "yup";
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;

const WorkorderView = () => {

  const [workorders, setWorkorders] = useState([]);
  const [orders, setOrders] = useState([]);
  const [orderdetails, setOrderDetails] = useState([]);
  const [orderMap, setordersMap]= useState([]);
  const { user } = useAuth();
  const [msg, msgContextHolder] = message.useMessage();
  const [isView, setIsView] = useState(false);
  const clientId = user.client;
  const [searchStatus] = useState('1');
  const [form] = Form.useForm();  

  
  const dateFormat = 'DD-MMM-YYYY'; //'MM/DD/YYYY'

  useEffect(() => {
    GetWorkOrderDetail('qUYk9mBle3HIxKNSCaD0');      
  } , [clientId, msg]);


  const GetWorkOrderDetail = (sInput) => {
    try {      
      const docRef = doc(firestore, `/${clientId}/details/workorders`,sInput);
     
      getDoc(doc(firestore, `/${clientId}/details/workorders`, sInput)).then(docSnap => {
        if (docSnap.exists()) {
          //console.log("Document data:", docSnap.data());          
          //setOrderDetails(docSnap.data()); 

          const data = docSnap.data();
          let woDetails = [];
          console.log("Document data:",data);  
          woDetails['Key'] = sInput;

          let woBasicDetails = [];
          woBasicDetails['BOL'] = data.bol;
          woBasicDetails['DeliveryDate'] = data.delDate;
          woBasicDetails['Status'] = data.woStatus;
          woDetails.push(woBasicDetails);

          let woDriverDetails = [];
          woDriverDetails['DriverId'] = data.driverid;
          woDriverDetails['DriverName'] = data.driver;
          woDetails.push(woDriverDetails);

          let woCompanyDetails = [];
          woCompanyDetails['CompanyId'] = data.customerid;
          woCompanyDetails['CompanyName'] = data.customer;
          woDetails.push(woCompanyDetails);

          let woTerminalDetails = [];
          woTerminalDetails['TerminalId'] = data.terminalid;
          woTerminalDetails['TerminalName'] = data.terminal;
          woTerminalDetails['TerminalAddress'] = data.terminalAddress;
          woDetails.push(woTerminalDetails);

          setordersMap("");
          let woOrderDetails = [];
          const entries = Object.entries(docSnap.data().ordersMap);
         
          entries.forEach(([key, value]) =>  {
            const order = [];
            let id =`${key}`             
            const entry = Object.entries(value);           
            
            order['key'] = `${key}`;  
            order['DelAddress'] = value.address;
            order['Comments'] = value.comments;
            order['DelDate'] = value.delDate;        
            let startDt = typeof value.delStart=== "string" && value.delStart;   
            let endDt = typeof value.delEnd=== "string" && value.delEnd;   
            if(startDt) {
              order['DelTiming'] = startDt + " - " + endDt;
            }                 
            order['Status'] = value.status; 
                        
            let docLinks = [];
            const docurl = Object.entries(value.docurls);
            docurl.forEach(([key, value]) =>  {             
              const docLink = {};            
                docLink['docKey'] = `${key}`;  
                docLink['docLink'] = value[0];
                docLink['docDate'] = value[1];
                docLink['docId'] = value[2];
                docLinks.push(docLink);             
            });          
            order.push(docLinks);
            woOrderDetails.push(order);                               
          });

          woDetails.push(woOrderDetails);
          console.log("woDetails=>",woDetails);
          setOrderDetails(woDetails); 
        }
      });       
    } catch(error) {
      msg.open({ type: 'error',  content: error.message,  duration: 10 });
    }      
  }
  const handleCancel = (e) => {
    //console.log(e); 
    setIsView(false);
  };  

  const cardstyle = {
    width:'100%',
  }  
  const inputStyle= {
    width:'300px',
  }
  const divStyle = { 
    width:'100%',
    float: 'left',
  }  
  const divLeft = {
    float: 'left',
    width:'80%',
  } 
  
  const divRight = {
    float: 'right',
  }  

  
  const style = {
    background: '#0092ff',
    padding: '8px 0',
  };

  const lableStyle={
    background: '#4096ff42',
    padding: '5px',    
    fontWeight: '500',
  }

  const dataStyle={
    background: '#eff2f6',
    padding: '5px',
  }

return (  
  <Card title="">
      <div style={cardstyle}>
          <div style={divStyle}>
              <div style={divLeft}>
                
              </div>
              <div style={divRight}>
              
              </div>
          </div>
        </div>
        <div>&nbsp;</div>  
        <div style={divStyle}>
        <Row>
          <Col span={6}></Col>
          <Col span={6}></Col>
          <Col span={6}></Col>
          <Col span={6}>
            <Button key="back" onClick={handleCancel}>Cancel</Button>&nbsp;&nbsp;&nbsp;
            <Button key="submit" type="primary" htmlType="submit">Print</Button>  
          </Col>
        </Row>
        <Divider orientation="left">Basic Details</Divider>   
        <Row>
          <Col span={6} style={lableStyle}>Workorder Id:</Col>
          <Col span={6} style={dataStyle}>{orderdetails.Key}</Col>
          <Col span={6} style={lableStyle}>BOL:</Col>
          <Col span={6} style={dataStyle}>{orderdetails[0].BOL}</Col>
        </Row>
       
        <Row>
          <Col span={6} style={lableStyle}>Delivery Date</Col>
          <Col span={6} style={dataStyle}>{orderdetails[0].DeliveryDate}</Col>
          <Col span={6} style={lableStyle}>Status</Col>
          <Col span={6} style={dataStyle}>            
            <span>         
            <Space size="middle">    
              <Tag color={orderdetails[0].Status === "Completed" ? "success" : (orderdetails[0].Status === "Unassigned" ? "error" : "processing")}  key={orderdetails[0].Status}>
                {orderdetails[0].Status}
              </Tag>
            </Space>
            </span>
        </Col>
        </Row>
        <Divider orientation="left">Driver Details</Divider>  
        <Row>
          <Col span={6} style={lableStyle}>Driver Name (ID)</Col>
          <Col span={18} style={dataStyle}>{orderdetails[1].DriverName} ({orderdetails[1].DriverId})</Col>        
        </Row>

        <Divider orientation="left">Company Details</Divider>  
        <Row>
          <Col span={6} style={lableStyle}>Company Name (ID)</Col>
          <Col span={18} style={dataStyle}>{orderdetails[2].CompanyName}  ({orderdetails[2].CompanyId})</Col>         
        </Row>
        <Divider orientation="left">Order Details</Divider>  
        {orderdetails[4].map((ord) => { 
          return (
            <Row style={{border:'1px solid #4096ffbf', marginBottom: '3px'}}>
              <Col span={3} style={lableStyle}>Address</Col>
              <Col span={3} style={dataStyle}>{ord.DelAddress}</Col>         
              <Col span={3} style={lableStyle}>Delivered On</Col>
              <Col span={3} style={dataStyle}>{ord.DelDate }</Col>
              <Col span={3} style={lableStyle}>Time Frame</Col>
              <Col span={3} style={dataStyle}>{ord.DelTiming}</Col>
              <Col span={3} style={lableStyle}>Status</Col>
              <Col span={3} style={dataStyle}>
              <span>         
                <Space size="middle">    
                  <Tag color={ord.Status === "Completed" ? "success" : (ord.Status === "Unassigned" ? "error" : "processing")}  key={ord.Status}>
                    {ord.Status}
                  </Tag>
                </Space>
                </span>
              </Col>
              <Col span={3} style={lableStyle}>Attachments</Col>    
              {orderdetails[4][0][0].map((img) => { 
                return ( 
                  <Col span={3} style={dataStyle}><img width={180} height={180} src={img.docLink}></img></Col>                     
                )}     
              )}  
              <Col span={3} style={lableStyle}>Comments</Col>
              <Col span={21} style={dataStyle}>{ord.Comments}</Col>   
            </Row>   
         )}     
        )}   
        
        <Divider orientation="left">Terminal Details</Divider>  
        <Row>
          <Col span={6} style={lableStyle}>Terminal Name</Col>
          <Col span={6} style={dataStyle}>{orderdetails[3].TerminalName} ({orderdetails[3].TerminalId})</Col>
          <Col span={6} style={lableStyle}>Address</Col>
          <Col span={6} style={dataStyle}>{orderdetails[3].TerminalAddress}</Col>
        </Row>
        <Divider orientation="left">Attachments</Divider> 
        <Row>           
          <Col span={6}><img width={180} height={180} src={orderdetails.docurls}></img></Col>
          <Col span={6}>&nbsp;</Col>
          <Col span={6}><img width={180} height={180} src='https://firebasestorage.googleapis.com/v0/b/fleet-poc-9e021.appspot.com/o/GSH%2Fworkorders%2FOf0eDZilAnoHaayEtjcL%2F20235102222021%2F2023510223511.jpg?alt=media&token=9459f677-d76c-4c00-a12f-bc45ec972610'></img></Col>
        </Row> 
        <Divider orientation="left"></Divider> 
        <Row>           
          <Col span={6}><img width={180} height={180} src='https://firebasestorage.googleapis.com/v0/b/fleet-poc-9e021.appspot.com/o/GSH%2Fworkorders%2FOf0eDZilAnoHaayEtjcL%2F20235102222021%2F2023510223457.jpg?alt=media&token=6b2db7be-acd5-4f42-b50a-829c0707daf2'></img></Col>
          <Col span={6}>&nbsp;</Col>
          <Col span={6}><img width={180} height={180} src='https://firebasestorage.googleapis.com/v0/b/fleet-poc-9e021.appspot.com/o/GSH%2Fworkorders%2FOf0eDZilAnoHaayEtjcL%2F20235102222021%2F2023510223511.jpg?alt=media&token=9459f677-d76c-4c00-a12f-bc45ec972610'></img></Col>
        </Row> 
        <Row>
          <Col span={6} style={{background: '#ffffff'}}></Col>
          <Col span={6}></Col>
          <Col span={6} style={{background: '#ffffff'}}></Col>
          <Col span={6}>
            <Button key="back" onClick={handleCancel}>Cancel</Button>&nbsp;&nbsp;&nbsp;
            <Button key="submit" type="primary" htmlType="submit">Print</Button>  
          </Col>
        </Row>
      
      
        </div>
      
    </Card>
  );
};

export default WorkorderView; 