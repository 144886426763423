import React from "react";
import { Outlet } from "react-router-dom";

import Content from "../components/Content";
import Sidebar from "../components/sidebar/Sidebar";
import Main from "../components/Main"
import Wrapper from "../components/Wrapper";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/Footer";
import Settings from "../components/Settings";

import navItems from "../components/sidebar/SidebarMenuItems"

const Dashboard = ({children}) => (    
    <React.Fragment>
        <Wrapper>
            <Sidebar items={navItems} />
            <Main>
                <Navbar />
                <Content>
                    {children}
                    <Outlet />
                </Content>
                <Footer />
            </Main>
        </Wrapper>
        <Settings />
    </React.Fragment>
);

export default Dashboard;