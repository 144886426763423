import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import ReceiptsList from "../../components/receipts/ReceiptsList";

const ReceiptsPage = () => (
    <React.Fragment>
        <Helmet title="Workorders" />
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Receipts</h1>
           <ReceiptsList></ReceiptsList>
        </Container>       
    </React.Fragment>
);

export default ReceiptsPage;