import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import WorkordersList from "../../components/workorders/WorkordersList";

const WorkordersPage = () => (
    <React.Fragment>
        <Helmet title="Work Orders" />
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Work Orders</h1>
            <WorkordersList />
        </Container>       
    </React.Fragment>
);

export default WorkordersPage;