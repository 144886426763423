import { EditOutlined, DeleteOutlined, UserOutlined,  SearchOutlined as SearchIcon,PlusSquareOutlined, UploadOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, DatePicker, Select,  Form, message, Modal, Row, Space,Switch,Upload,Progress, Popconfirm, Table, Input,Radio,FloatButton } from "antd";
import { collection, onSnapshot, query,deleteField } from "firebase/firestore";
import { useEffect, useState,useRef } from "react";
import { firestore,storage } from "../../firebase/Firebase";
import useAuth from '../../hooks/useAuth';
import { doc, setDoc,updateDoc } from "firebase/firestore"; 
import { getStorage,ref,uploadBytes } from "firebase/storage";
import dayjs from 'dayjs';
import moment from "moment/moment";


const DriversList = () => {
  const [drivers, setDrivers] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingDriver, setEditingDriver] = useState(null);
  const { user } = useAuth();
  const [msg, msgContextHolder] = message.useMessage();  
  const clientId = user.client;
  const [form] = Form.useForm();  
  const [searchStatus] = useState('1');
  const [filePhotoList, setPhotoFileList] = useState([]);
  const [fileDLList, setDLFileList] = useState([]);
  const [fileTwicList, setTwicFileList] = useState([]);
  const [editForm] = Form.useForm();  
  const [proImgURL, setProImgURL] = useState('');
  const [dlImgURL, setDlImgURL] = useState('');
  const [twicImgURL, setTwicImgURL] = useState('');

  const columns = [ 
    {
        title: '',
        dataIndex: 'profUrl',
        render: profileImgURL => <Avatar shape="square" size="large" src={ profileImgURL } icon={<UserOutlined />} />,
        width: '5%',
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      sorter: true,      
      width: '20%',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      sorter: true,      
      width: '20%',
    },
    {
      title: 'Email',
      dataIndex: 'emailId',      
      width: '15%',
    },
    {
      title: 'Phone',
      dataIndex: 'phNo',
      width: '10%',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      width: '20%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (record) => (     
        <Space size="middle">
        <Switch checked={record === 1 ? true : false}  disabled/>
        </Space>
      ),
    },
    {
      title: 'Edit',
      key: 'action',
      width: '5%',
      render: (_, record) => (
        <Space size="middle">
           <EditOutlined  onClick={()=> {onEditDriver(record);}} title="Edit" style={{color:'green'}}></EditOutlined>
        </Space>
      ),
    },
    {
      title: 'Delete',
      key: 'action',
      width: '5%',
      render: (_, record) => (
        <Space size="middle"> 
        <Popconfirm   title="Delete the driver" description="Are you sure to delete this driver?"
        onConfirm={() => handleDelete(record)} onCancel={handleCancel} okText="Yes" cancelText="No">
        <DeleteOutlined title="Delete" style={{color:'red'}}/> 
        </Popconfirm>
        </Space>
      ),
    }, 
  ];


  const handleOk = (e) => {
    console.log(e);
    setIsEditing(false);
  };

  const handleCancel = (e) => {
    console.log(e);
    setIsEditing(false);
  };

  useEffect(() => {
    ListDrivers('');
  } , [clientId, msg]);

const ListDrivers = (sInput) => {
  try {
    const qry = query(collection(firestore, `/${clientId}/details/drivers`)); 
    let srhText =sInput.srchText;
    let srhStatus = sInput.srchStatus;

    const unsubscribe = onSnapshot(qry, (snapshot) => {
      if(snapshot.docs)
      { 
        const data = snapshot.docs.map(doc => doc.data());  
        const driverData = [];   
        snapshot.forEach((doc) => {        
          //console.log(doc.id, " => ", doc.data());
          const driver = {};
          const filDriver = doc.data();
          if(filDriver.status !== 2)
          {
            driver['key'] = doc.id;   
            driver['firstName'] =   filDriver.firstName;
            driver['lastName'] =   filDriver.lastName;
            driver['address'] =   filDriver.address;            
            driver['emailId'] =   filDriver.emailId;           
            driver['phNo'] =   filDriver.phNo;
            driver['dob'] = filDriver.dob;
            driver['profUrl'] =   filDriver.profUrl;
            driver['licenseUrl'] =   filDriver.licenseUrl;
            driver['twicexp'] =   filDriver.twicexp;
            driver['twicUrl'] =   filDriver.twicUrl;
            driver['status'] =  filDriver.status; 
            driverData.push(driver);   
          }        
        }); 

        
        let filteredDrivers=driverData;
        if(srhText !== undefined) {
          filteredDrivers = data.filter(
            driver => {
              return (
                (typeof driver.firstName === "string") && (driver.firstName.toLowerCase().includes(srhText.toLowerCase())) ||
                typeof driver.lastName === "string" && driver.lastName.toLowerCase().includes(srhText.toLowerCase()) ||
                typeof driver.emailId === "string" && driver.emailId.toLowerCase().includes(srhText.toLowerCase()) ||
                typeof driver.phNo === "string" && driver.phNo.toLowerCase().includes(srhText.toLowerCase())
              );
            }
          );
        }    

        if(srhStatus === "-1"){
          filteredDrivers = filteredDrivers.filter(
            driver => {
              return (
                typeof driver.status === "string" && driver.status.toLowerCase().includes("1") ||
                typeof driver.status === "string" && driver.status.toLowerCase().includes("0")
              );
            }
          );  
        } 

        if(srhStatus === "1" || srhStatus === "0"){
          filteredDrivers = filteredDrivers.filter(
            driver => {
              return (
                typeof driver.status === "string" && driver.status.toLowerCase().includes(srhStatus.toLowerCase())  
              );
            }
          ); 
        }
        
        /*
        const drivers = filteredDrivers.map(doc => ({
          key:doc.id,
          profUrl:doc.profUrl,
          firstName: doc.firstName,
          lastName: doc.lastName,        
          emailId: doc.emailId,
          phPrefix: doc.phPrefix,
          phNo: doc.phNo,
          status: doc.status,                           
        }));
*/
        setDrivers(filteredDrivers); 
      }
      else
      {
        msg.open({type: 'warning', content: 'No driver found.'});
      }
    },(error) => {         
      msg.open({type: 'error', content: error.message});        
    },
    );
    return unsubscribe;
  } catch(error) {
      msg.open({
        type: 'error',
        content: error.message,
        duration: 10
      });
  }   
};

//Edit Driver Details
const onSaveDriver = (values) => {
  try
  { 
    let driverName = values.FirstName + ' ' +values.LastName;
    if(values.Status ===1){
      const driDocRef = doc(firestore, clientId, "lookups");     
      setDoc(driDocRef, { 'driversMap' : {[values.key] : driverName}  },{ merge: true });   
    }else{
      const driDocRef = doc(firestore, clientId, "lookups");
      setDoc(driDocRef, { 'driversMap' : {[values.key] : deleteField() }},{ merge: true });  
    }

    let profUrl= `${clientId}/drivers/`+ values.key + '_profile.jpg'
    let licenseUrl = `${clientId}/drivers/`+ values.key + '.jpg'
    let twicUrl= `${clientId}/drivers/`+ values.key + '_twic.jpg'
    
    //Upload profile photo
    filePhotoList.forEach((file) => {        
      const storage = getStorage();
      const storageRef = ref(storage, profUrl);
      uploadBytes(storageRef, file).then((snapshot) => {
        console.log('Profile photo re-uploaded!');          
      });        
    });

    //Upload Driving License
    fileDLList.forEach((fileDl) => {
      const storage = getStorage();     
      const storageRef = ref(storage, licenseUrl);
      uploadBytes(storageRef, fileDl).then((snapshot) => {
        console.log('Driving License re-uploaded!');                  
      });        
    });

    //Upload TWIC Card
    fileTwicList.forEach((fileTc) => {
      const storage = getStorage();       
      const storageRef = ref(storage, twicUrl);
      uploadBytes(storageRef, fileTc).then((snapshot) => {
        console.log('TWIC Card re-uploaded!');          
      });        
    });

    let imgProfUrl= 'https://firebasestorage.googleapis.com/v0/b/talentmindsllc-424e7.appspot.com/o/'+encodeURIComponent(profUrl)+'?alt=media&token=1829cc12-79fa-4500-b31c-6330072ea3b4&_gl=1*s2re9d*_ga*ODU3OTA3OTAuMTY4MzM5NjMzOA..*_ga_CW55HF8NVT*MTY4NTgxOTk3OC42Ni4xLjE2ODU4MjIxNjkuMC4wLjA.'
    let imgLicenseUrl = 'https://firebasestorage.googleapis.com/v0/b/talentmindsllc-424e7.appspot.com/o/'+encodeURIComponent(licenseUrl)+'?alt=media&token=1829cc12-79fa-4500-b31c-6330072ea3b4&_gl=1*s2re9d*_ga*ODU3OTA3OTAuMTY4MzM5NjMzOA..*_ga_CW55HF8NVT*MTY4NTgxOTk3OC42Ni4xLjE2ODU4MjIxNjkuMC4wLjA.'
      
    let twicDate = '';let imgTwicUrl = '';
    if(values.TwicExpDate != undefined){
      twicDate = values.TwicExpDate.format(dateFormat);    
      imgTwicUrl = 'https://firebasestorage.googleapis.com/v0/b/talentmindsllc-424e7.appspot.com/o/'+encodeURIComponent(twicUrl)+'?alt=media&token=1829cc12-79fa-4500-b31c-6330072ea3b4&_gl=1*s2re9d*_ga*ODU3OTA3OTAuMTY4MzM5NjMzOA..*_ga_CW55HF8NVT*MTY4NTgxOTk3OC42Ni4xLjE2ODU4MjIxNjkuMC4wLjA.'
    }

    //Clients Collections List
    /*updateDoc(doc(firestore, '/Clients/users/driver', values.key), {
      client: `${clientId}`,
      login: values.EmailId  
    });*/

    updateDoc(doc(firestore, `/${clientId}/details/drivers`, values.key), {
      firstName: values.FirstName,
      lastName: values.LastName,
      lfirstName: values.FirstName,
      llastName: values.LastName,
      address: values.Address,
      dob: values.DateOfBirth.format(dateFormat),
      emailId: values.EmailId,
      phNo: values.Phone,
      licenseUrl: imgLicenseUrl,
      profUrl: imgProfUrl,
      twicUrl: imgTwicUrl,
      twicexp: twicDate,
      status: values.Status,
    });

    msg.open({type: 'success', content: 'Driver details has been saved successfully!'});  
    handleCancel();        
  } catch  (error){
    msg.open({type: 'error', content: error.message});           
  }
};


const handleDelete = (record) => {
  console.log("record.key=>",record.key);
  try
  {   
    //Delete Customer data in 'lookups' document
    const termDocRef = doc(firestore, clientId, "lookups");
    setDoc(termDocRef, { 'driversMap' : {[record.key] : deleteField() }},{ merge: true });  

    //Update Customer data in 'customers' collection
    updateDoc(doc(firestore, `/${clientId}/details/drivers`,record.key ), {   
      status:  2,
    });
    msg.open({type: 'success', content: 'Driver details has been deleted successfully.'});      
    
    handleCancel();
  } catch (error) {
    msg.open({type: 'error', content: error.message});           
  }
}

//Search functionality
const SearchDrivers = (e) => { 
  console.log(e);
  let srchText = e[0].value;
  let srchStatus = e[1].value;
  const SearchInput = {
    srchText: srchText,
    srchStatus: srchStatus
  }
  ListDrivers(SearchInput);    
}

  //Popup
  const [isModalOpen, setIsModalOpen] = useState(false);   
  const showModal = () => {
    form.resetFields();
    setIsModalOpen(true);
  };
  
  const handleCancelNew = () => {
    setIsModalOpen(false);
  };
  
const searchOptions = [
  {
    label: 'Active',
    value: '1',
  },
  {
    label: 'Inactive',
    value: '0',
  },
  {
    label: 'All',
    value: '-1',
  },
];

const cardstyle = {
  width:'100%',
}

const inputStyle= {
  width:'300px',
}

const divStyle = { 
  width:'100%',
  float: 'left',
}

const divLeft = {
  float: 'left',
  width:'80%',
}

const divRight = {
  float: 'right',
}
const hideTxtBx = {
  display: 'none',
}

const { Search } = Input;

const dateFormat = 'DD-MMM-YYYY';
  
const propsPP = {
  onRemove: (file) => {
    const index = filePhotoList.indexOf(file);
   // const newFileList = filePhotoList.slice();
    const newFileList = filePhotoList.slice();
    newFileList.splice(index, 1);
    setPhotoFileList(newFileList);
  },
  beforeUpload: (file) => {
    setPhotoFileList([...filePhotoList, file]);
    return false;
  },
  filePhotoList,
};

const propsDL = {
  onRemove: (file) => {
    const index = fileDLList.indexOf(file);
    const newFileList = fileDLList.slice();
    newFileList.splice(index, 1);
    setDLFileList(newFileList);
  },
  beforeUpload: (file) => {
    setDLFileList([...fileDLList, file]);
    return false;
  },
  fileDLList,
};

const propsTwic = {
  onRemove: (file) => {
    const index = fileTwicList.indexOf(file);
    const newFileList = fileTwicList.slice();
    newFileList.splice(index, 1);
    setTwicFileList(newFileList);
  },
  beforeUpload: (file) => {
    setTwicFileList([...fileTwicList, file]);
    return false;
  },
  fileTwicList,
};


//Edit Link onClick method
const onEditDriver = (record) => {
  setIsEditing(true);
  setEditingDriver({...record});  
  //console.log("record=>",record);
   
  setProImgURL(record.profUrl);
  setDlImgURL(record.licenseUrl); 
  setTwicImgURL(record.twicUrl);

  editForm.setFieldsValue({ key: record.key });
  editForm.setFieldsValue({ FirstName: record.firstName });
  editForm.setFieldsValue({ LastName: record.lastName });
  editForm.setFieldsValue({ Address: record.address });
  editForm.setFieldsValue({ DateOfBirth: dayjs(record.dob, dateFormat) });
  editForm.setFieldsValue({ EmailId: record.emailId });
  editForm.setFieldsValue({ Phone: record.phNo });
  editForm.setFieldsValue({ ProfUrl: record.profUrl });
  editForm.setFieldsValue({ LicenseUrl: record.licenseUrl });
  if(record.twicexp != undefined){
    //editForm.setFieldsValue({ TwicUrl: record.twicUrl });
    editForm.setFieldsValue({ TwicExpDate:  dayjs(record.twicexp, dateFormat) });
  }
  editForm.setFieldsValue({ Status:record.status});  
};

  return (
    <Row gutter={{
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
      }}>        
        
          <Card style={cardstyle}>
          {msgContextHolder} 
          <div style={cardstyle}>
            <div style={divStyle}>
                <div style={divLeft}> 
                <Space>
                <Form form={form} layout="inline" initialValues={{ SearchStatus: '-1'}} 
                onFieldsChange={(_, allFields) => {  SearchDrivers(allFields); }}>
                  <Form.Item label="" name="SearchWord" suffixIcon={<SearchIcon />} rules={[{ message: 'Please enter search word' }]}  style={inputStyle} >
                  <Search placeholder="Search by Name, Person, Email & Phone" allowClear maxLength={20} title="Search by Name, Person, Email & Phone"/>
                  </Form.Item>
                  <Form.Item label="" name="SearchStatus" rules={[{ message: 'Please select the terminal status!' }]} >
                    <Radio.Group options={searchOptions} value={searchStatus} optionType="button" buttonStyle="solid" />
                  </Form.Item>
                </Form>
                </Space>        
              </div>
              <div style={divRight}>
              <Space><Button type="primary" icon={<PlusSquareOutlined />} onClick={showModal}>Driver</Button></Space>
              </div>    
            </div>               
          </div>
          <div>&nbsp;</div>  
            <Table
                rowKey='emailId'
                columns={columns}
                dataSource={drivers}
                size="small"
              />              
          </Card>
          <FloatButton.BackTop />
          <ModalForm open={isModalOpen} onCancel={handleCancelNew} />    
          <Modal title="Edit Driver" centered open={isEditing} okText="Save changes" onOk={handleOk} onCancel={handleCancel} footer={[]} >
            <Form form={editForm} layout="horizontal" labelCol={{span: 9}} wrapperCol={{span: 16}} autoComplete="off" 
               onFinish={onSaveDriver}>
                  <Form.Item label="Key" name="key" style={hideTxtBx} ><Input disabled={true}  /></Form.Item>
                <Form.Item label="First Name" name="FirstName" rules={[{ required: true, message: 'Please enter driver first name!' }]} >
                  <Input/>
                </Form.Item>
                <Form.Item label="Last Name" name="LastName" rules={[{ required: true, message: 'Please enter driver last name!' }]} >
                  <Input/>
                </Form.Item>
                <Form.Item  label="Address" name="Address" rules={[{ required: true, message: 'Please enter driver address!' }]}>
                  <Input/>
                </Form.Item>  
                <Form.Item label="Email" name="EmailId"  rules={[{ required: true, type: 'email' }]} help="ex. name@domain.com">
                  <Input disabled={true}/>
                </Form.Item>
                <Form.Item label="Phone" name="Phone" rules={[{ required: true, type:'string' }]} help="ex. +15717894187">
                  <Input/>
                </Form.Item> 
                <Form.Item label="Date of Birth" name="DateOfBirth" rules={[{ required: true, type: 'date', message: 'Please select your Date of Birth!' }]} >
                  <DatePicker format={dateFormat}/>
                </Form.Item>  
                <Form.Item label="Profile Photo" name="ProfilePhoto" rules={[{ required: false, message: 'Please upload your photo!' }]}>
                  <Space>
                    <Upload  {...propsPP}  accept="image/*" crossOrigin='anonymous' fileList={filePhotoList}  maxCount={1}>
                      <Button icon={<UploadOutlined />}>Select Profile Photo</Button>
                    </Upload>     
                    <Avatar shape="square" size="small" src={ proImgURL } icon={<UserOutlined />} />
                  </Space>
                </Form.Item>
                <Form.Item label="Driving License" name="DrivingLicense" rules={[{ required: false, message: 'Please upload your driving license!' }]}>
                <Space><Upload {...propsDL}  accept="image/*" crossOrigin='anonymous' fileList={fileDLList}  maxCount={1}>
                  <Button icon={<UploadOutlined />}>Select Driving License</Button>
                </Upload>
                <Avatar shape="square" size="small" src={ dlImgURL } icon={<UserOutlined />} /></Space>
                </Form.Item>
                <Form.Item label="TWIC Card Expiration Date" name="TwicExpDate" rules={[{  required: true,type: 'date', message: 'Please select TWIC Card Expiration Date!' }]} >
                  <DatePicker format={dateFormat}/>
                </Form.Item>  
                <Form.Item label="TWIC Card" name="TWICCard" >
                <Space> <Upload {...propsTwic}  accept="image/*" crossOrigin='anonymous' fileList={fileTwicList}  maxCount={1}>
                  <Button icon={<UploadOutlined />}>Select TWIC Card</Button>
                </Upload>
                <Avatar shape="square" size="small" src={ twicImgURL } icon={<UserOutlined />} /></Space>
                </Form.Item>
                <Form.Item label="Status" name="Status">
                  <Radio.Group optionType="button" buttonStyle="solid">
                  <Radio value={1}>Active</Radio>
                  <Radio value={0}>Inactive</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item>          
                  <Button key="back" onClick={handleCancel}>Cancel</Button>&nbsp;&nbsp;&nbsp;
                  <Button key="submit"  type="primary" htmlType="submit">Save Changes</Button>         
                </Form.Item>                 
              </Form>
        </Modal>
      </Row>
  );
};



//New Driver Form.
// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({ form, open }) => {
  const prevOpenRef = useRef();
  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;
  useEffect(() => {
    if (!open && prevOpen) {
      form.resetFields();
    }
  }, [form, prevOpen, open]);
};


//const [status, setStatus] = useState('1');
const { Option } = Select;
const options = [
  {
    label: 'Active',
    value: '1',
  },
  {
    label: 'Inactive',
    value: '0',
  },
];

const ModalForm = ({ open, onCancel }) => {
  const [form] = Form.useForm();
  const [terinalStatus, setValue] = useState('1');
  const { user } = useAuth();
  useResetFormOnCloseModal({
    form,
    open,
  });
  const onOk = () => {       
    //form.submit();    
  };
  const [msg, msgContextHolder] = message.useMessage();  
  useEffect(() => {
    ListDrivers('');
  } , [msg]);

  const clientId = user.client;

  const [filePhotoList, setPhotoFileList] = useState([]);
  const [fileDLList, setDLFileList] = useState([]);
  const [fileTwicList, setTwicFileList] = useState([]);
   
  const [driversSearch, setDrivers] = useState([]);
  const ListDrivers = () => {
    try {
      const qry = query(collection(firestore, `/${clientId}/details/drivers`)); 
      onSnapshot(qry, (snapshot) =>  {    
        if(snapshot.docs) {       
            const customerData = [];   
            snapshot.forEach((doc) => {        
            
              const customer = {};
              customer['key'] = doc.id;              
              const filCustomers = doc.data();
              customer['emailId'] =   filCustomers.emailId;          
              customerData.push(customer);           
            });
  
            setDrivers(customerData);
        }       
      });
     } catch (error) {
      messageApi.open({type: 'error', content: error.message}); 
    }
  };
function checkExists(email) {
  let retDriver = true; 
  try
  {
    let driversFilterData = driversSearch.filter(
      driver => {
        return (
          typeof driver.emailId === "string" && driver.emailId.toLowerCase().includes(email.toLowerCase())
        );
      }
    );  
    let cntTerminal = driversFilterData.length; 
    if(cntTerminal > 0)
    {
      retDriver = false; 
    }  
  } catch (error) {
    messageApi.open({type: 'error', content: error.message}); 
  }    
  return retDriver; 
}

  const onFinish = (values) => { 
    try
    {
      const isExist = checkExists(values.EmailId);
      if(isExist)
      {
        let driverId = generateId(10);

        let profUrl= `${clientId}/drivers/`+ driverId + '_profile.jpg'
        let licenseUrl = `${clientId}/drivers/`+ driverId + '.jpg'
        let twicUrl= `${clientId}/drivers/`+ driverId + '_twic.jpg'
      
        let imgProfUrl= 'https://firebasestorage.googleapis.com/v0/b/talentmindsllc-424e7.appspot.com/o/'+encodeURIComponent(profUrl)+'?alt=media&token=1829cc12-79fa-4500-b31c-6330072ea3b4&_gl=1*s2re9d*_ga*ODU3OTA3OTAuMTY4MzM5NjMzOA..*_ga_CW55HF8NVT*MTY4NTgxOTk3OC42Ni4xLjE2ODU4MjIxNjkuMC4wLjA.'
        let imgLicenseUrl = 'https://firebasestorage.googleapis.com/v0/b/talentmindsllc-424e7.appspot.com/o/'+encodeURIComponent(licenseUrl)+'?alt=media&token=1829cc12-79fa-4500-b31c-6330072ea3b4&_gl=1*s2re9d*_ga*ODU3OTA3OTAuMTY4MzM5NjMzOA..*_ga_CW55HF8NVT*MTY4NTgxOTk3OC42Ni4xLjE2ODU4MjIxNjkuMC4wLjA.'
        
        const driDocRef = doc(firestore, clientId, "lookups");
        let driverName = values.FirstName + ' ' +values.LastName;
        setDoc(driDocRef, { 'driversMap' : {[driverId] : driverName}  },{ merge: true });   
  
        let twicDate = '';let imgTwicUrl = '';
        if(values.TwicExpDate != undefined){
          twicDate = values.TwicExpDate.format(dateFormat);    
          imgTwicUrl = 'https://firebasestorage.googleapis.com/v0/b/talentmindsllc-424e7.appspot.com/o/'+encodeURIComponent(twicUrl)+'?alt=media&token=1829cc12-79fa-4500-b31c-6330072ea3b4&_gl=1*s2re9d*_ga*ODU3OTA3OTAuMTY4MzM5NjMzOA..*_ga_CW55HF8NVT*MTY4NTgxOTk3OC42Ni4xLjE2ODU4MjIxNjkuMC4wLjA.'
        }

         //Clients Collections List
        setDoc(doc(firestore, '/Clients/users/driver',driverId ), {
          client: `${clientId}`,
          login: values.EmailId,
          status: 'Pending',      
        });

        setDoc(doc(firestore, `/${clientId}/details/drivers`,driverId ), {
          firstName: values.FirstName,
          lastName: values.LastName,
          lfirstName: values.FirstName,
          llastName: values.LastName,
          address: values.Address,
          dob: values.DateOfBirth.format(dateFormat),
          emailId: values.EmailId, 
          phNo: values.Phone,
          licenseUrl: imgLicenseUrl,
          profUrl: imgProfUrl,
          twicUrl: imgTwicUrl,
          twicexp: twicDate,
          status: values.Status,
        });

         //Upload profile photo
         filePhotoList.forEach((file) => {        
          const storage = getStorage();
          const storageRef = ref(storage, profUrl);
          uploadBytes(storageRef, file).then((snapshot) => {
            console.log('Profile photo uploaded!');          
          });        
        });

        //Upload Driving License
        fileDLList.forEach((fileDl) => {
          const storage = getStorage();     
          const storageRef = ref(storage, licenseUrl);
          uploadBytes(storageRef, fileDl).then((snapshot) => {
            console.log('Driving License uploaded!');                  
          });        
        });

        //Upload TWIC Card
        fileTwicList.forEach((fileTc) => {
          const storage = getStorage();       
          const storageRef = ref(storage, twicUrl);
          uploadBytes(storageRef, fileTc).then((snapshot) => {
            console.log('TWIC Card uploaded!');          
          });        
        });

        success();
        onCancel();
      }
    } catch(error) {
      console.log(error);
      //error();
    }
  };

  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Terminal details has been added successfully.',
    });
  };
  const error = () => {
    messageApi.open({
      type: 'error',
      content: 'This is an error message, Please reveiw the data and save again!',
    });
  };
  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'This is a warning message',
    });
  };

  function generateId(length) {
    let result = '';
    const characters = '0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}  

//const dateFormat = 'MM/DD/YYYY';
const dateFormat = 'DD-MMM-YYYY';

const propsPP = {
  onRemove: (file) => {
    const index = filePhotoList.indexOf(file);
    const newFileList = filePhotoList.slice();
    newFileList.splice(index, 1);
    setPhotoFileList(newFileList);
  },
  beforeUpload: (file) => {
    setPhotoFileList([...filePhotoList, file]);
    return false;
  },
  filePhotoList,
};

const propsDL = {
  onRemove: (file) => {
    const index = fileDLList.indexOf(file);
    const newFileList = fileDLList.slice();
    newFileList.splice(index, 1);
    setDLFileList(newFileList);
  },
  beforeUpload: (file) => {
    setDLFileList([...fileDLList, file]);
    return false;
  },
  fileDLList,
};

const propsTwic = {
  onRemove: (file) => {
    const index = fileTwicList.indexOf(file);
    const newFileList = fileTwicList.slice();
    newFileList.splice(index, 1);
    setTwicFileList(newFileList);
  },
  beforeUpload: (file) => {
    setTwicFileList([...fileTwicList, file]);
    return false;
  },
  fileTwicList,
};
 
return (   
  <Modal title="New Driver" open={open} onOk={onOk} onCancel={onCancel} footer={[]} centered maskClosable={false} >
    <Form form={form} name="frmNewTerminal"  onFinish={onFinish} layout="horizontal" labelCol={{span: 9}} wrapperCol={{span: 14}} initialValues={{ Status: 1}}>
      <Form.Item label="First Name" name="FirstName" rules={[{ required: true, message: 'Please enter driver first name!' }]} >
        <Input/>
      </Form.Item>
      <Form.Item label="Last Name" name="LastName" rules={[{ required: true, message: 'Please enter driver last name!' }]} >
        <Input/>
      </Form.Item>
      <Form.Item  label="Address" name="Address" rules={[{ required: true, message: 'Please enter driver address!' }]}>
        <Input/>
      </Form.Item>  
      <Form.Item label="Email" name="EmailId" rules={[{ required: true, type: 'email' }]} help="ex. name@domain.com">
        <Input/>
      </Form.Item>
      <Form.Item label="Phone" name="Phone" rules={[{ required: true, type:'string' }]} help="ex. +15717894187">
        <Input/>
      </Form.Item> 
      <Form.Item label="Date of Birth" name="DateOfBirth" rules={[{ required: true, type: 'date', message: 'Please select your Date of Birth!' }]} >
        <DatePicker format={dateFormat}/>
      </Form.Item>  
      <Form.Item label="Profile Photo" name="ProfilePhoto" rules={[{ required: false, message: 'Please upload your photo!' }]}>
        <Upload {...propsPP}  accept="image/*" crossOrigin='anonymous'>
        <Button icon={<UploadOutlined />}>Select Profile Photo</Button>
      </Upload>     
      </Form.Item>
      <Form.Item label="Driving License" name="DrivingLicense" rules={[{ required: false, message: 'Please upload your driving license!' }]}>
      <Upload {...propsDL}  accept="image/*" crossOrigin='anonymous'>
        <Button icon={<UploadOutlined />}>Select Driving License</Button>
      </Upload>
      </Form.Item>
      <Form.Item label="TWIC Card Expiration Date" name="TwicExpDate" rules={[{   required: true,type: 'date', message: 'Please select the TWIC Card expiration date!' }]} >
        <DatePicker  format={dateFormat}/>
      </Form.Item>  
      <Form.Item label="TWIC Card" name="TWICCard">
        <Upload {...propsTwic}  accept="image/*" crossOrigin='anonymous'>
        <Button icon={<UploadOutlined />}>Select TWIC Card</Button>
      </Upload>
      </Form.Item>
      <Form.Item label="Status" name="Status">
        <Radio.Group optionType="button" buttonStyle="solid">
        <Radio value={1}>Active</Radio>
        <Radio value={0}>Inactive</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item>          
        <Button key="back" onClick={onCancel}>Cancel</Button>&nbsp;&nbsp;&nbsp;
        <Button key="submit"  type="primary" htmlType="submit">Save</Button>         
      </Form.Item> 
   </Form>
  { contextHolder}
  </Modal>
  );
};
export default DriversList; 